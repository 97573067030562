import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";

export const SImage = styled("img")`
  display: block;
  width: 100%;
  height: auto;
`;

export const SStack = styled(Stack)`
  margin-top: 18px;
`;

export const googleAuthInitialValues = {
  secret: "",
  fa2: "",
};

export interface IGoogleAuthFields {
  secret: string;
  fa2: string;
}

export const googleAuthSchema = Yup.object().shape({
  secret: Yup.string(),
  fa2: Yup.string()
    .required()
    .min(6, "Minimum 6 symbols")
    .max(6, "Maximum 6 symbols")
    .matches(/^\d+$/, "Only numbers"),
});

export const fields = [
  {
    name: "secret",
    type: "text",
    label: "Secret",
    id: 1,
    maxRows: 4,
    multiline: true,
    variant: "standard",
    width: "90%",
    disabled: true,
  },
  {
    name: "fa2",
    type: "text",
    label: "2FA Code",
    id: 2,
    inputProps: {
      maxLength: 6,
    },
    variant: "standard",
    width: "90%",
    pattern: /^[0-9\b]+$/,
  },
];
