import { useEffect } from "react";

const Notify = ({ delay, fn, audioPlay }: any) => {
  useEffect(() => {
    let timeout = setTimeout(() => {
      fn();
      audioPlay();
    }, delay);
    return () => clearTimeout(timeout);
  }, []);
  return <></>;
};

export default Notify;
