import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "shared/api/xhr";

export const offersApi = baseApi.injectEndpoints({
  endpoints: (build): EndpointDefinitions =>
    ({
      fetchOfferCompetitions: build.query({
        query: ({ offerHash, ...params }) => ({
          url: `/offer-competitions:${offerHash}`,
          method: "GET",
          params,
        }),
      }),
      updateKeepActive: build.mutation({
        query: ({ id, body }) => ({
          url: `/offers:${id}/keep-active`,
          method: "POST",
          body,
        }),
      }),
      updateActive: build.mutation({
        query: ({ id, url, body, account_id }) => ({
          url: `/offers:${id}/${url}?account_id=${account_id}`,
          method: "POST",
          body,
        }),
      }),
      offerResetCompete: build.mutation({
        query: ({ ...params }) => ({
          url: "/offer-competitions/disable-compete",
          method: "POST",
          params,
        }),
      }),
    } as EndpointDefinitions),
});

export const {
  useOfferResetCompeteMutation,
  useUpdateKeepActiveMutation,
  useUpdateActiveMutation,
  useFetchOfferCompetitionsQuery,
}: any = offersApi;
