import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { baseApi } from "../../xhr";

export const fakeCustomerApi = baseApi.injectEndpoints({
  endpoints: (build) =>
    ({
      addFakeCustomer: build.mutation({
        query: ({ body, ...params }) => ({
          url: "/fake-customers",
          method: "POST",
          body,
          params,
        }),
      }),
      editFakeCustomer: build.mutation({
        query: ({ body, ...params }) => ({
          url: `/fake-customers:${body.id}`,
          method: "PATCH",
          body,
          params,
        }),
      }),
    } as EndpointDefinitions),
});

export const { useAddFakeCustomerMutation, useEditFakeCustomerMutation }: any =
  fakeCustomerApi;
