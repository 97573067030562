import { useAppSelector } from "app/hooks";
import React, { useState } from "react";
import useAction from "shared/hooks/useAction";
import { notify } from "shared/utils/functions/notify";
import Notify from "widgets/Trades/Notify";

import { axiosRequest } from "../../shared/api/xhr";

const TradesNotifyAlarm = () => {
  const tradeNotifies = useAppSelector(
    (state) => state.dictionaries.tradesNotifies
  );
  const { deleteNotify } = useAction();
  const [audio] = useState(
    new Audio(process.env.PUBLIC_URL + "/toast_sound.mp3")
  );

  const fn = (key: string) => {
    axiosRequest
      .get(`/trades/check/payment-card:${tradeNotifies[key].hash}`)
      .then((result: any) => {
        deleteNotify(tradeNotifies[key]);
        notify(
          result.cardVerified ? "success" : "error",
          !result.cardVerified
            ? "Карта не активирована или не прошла"
            : "success"
        );
      });
  };
  const audioPlay = () => {
    audio.play();
  };
  if (!Object.keys(tradeNotifies).length) return <></>;
  return (
    <>
      {Object.keys(tradeNotifies).map((el) => (
        <React.Fragment key={tradeNotifies[el].id}>
          <Notify
            audioPlay={audioPlay}
            delay={tradeNotifies[el].notify}
            fn={() => fn(el)}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default TradesNotifyAlarm;
