import { createSlice } from "@reduxjs/toolkit";

export type RoleType = {
  id: string;
  name: string;
  type: string;
};

interface IInitialValues {
  roles: RoleType[];
  isPaxfulNotifyOtherUsers: boolean;
  tradesNotifies: any;
}

const initialState: IInitialValues = {
  roles: [],
  isPaxfulNotifyOtherUsers: false,
  tradesNotifies: JSON.parse(sessionStorage.getItem("notifies") || "{}") || {},
};

export const dictionariesModel = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {
    fetchRolesActions: (state, { payload }) => {
      state.roles = payload;
    },
    setIsPaxfulNotifyOtherUsers: (state) => {
      state.isPaxfulNotifyOtherUsers = !state.isPaxfulNotifyOtherUsers;
    },
    clearNotifies: (state) => {
      state.tradesNotifies = {};
    },
    updateNotifies: (state, { payload }) => {
      state.tradesNotifies = payload;
    },
    deleteNotify: (state, { payload }) => {
      delete state.tradesNotifies[payload.id];
      sessionStorage.setItem("notifies", JSON.stringify(state.tradesNotifies));
    },
    setTradesNotifies: (state, { payload }) => {
      state.tradesNotifies = { ...state.tradesNotifies, [payload.id]: payload };
    },
  },
});

const dictionaryActions = dictionariesModel.actions;
export { dictionaryActions };

export default dictionariesModel.reducer;
