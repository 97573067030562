import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import { FormikProps } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FieldType } from "shared/constants";
import CheckboxField from "shared/ui/Checkbox";
import FieldPassword from "shared/ui/FIeldPassword";
import GroupCheckbox from "shared/ui/GroupCheckbox";
import MultiSelectField from "shared/ui/MultiSelectField";
import SelectField from "shared/ui/SelectField";
import SliderRange from "shared/ui/SliderRange";
import TextFieldInput from "shared/ui/TextField";

import DatePicker from "../../shared/ui/DatePicker";

interface formFieldTypeProps {
  x: FieldType;
  formik: FormikProps<any>;
  disabled?: boolean;
  filterData?: any;
  isFetchingErrors?: any;
  checkOnChangeField?: (arg: boolean) => void;
}

const FormFieldType = ({
  formik,
  x,
  isFetchingErrors,
  disabled,
  checkOnChangeField,
}: formFieldTypeProps) => {
  const { values, setFieldValue } = formik;
  const { t } = useTranslation("common");
  switch (x.type) {
    case "checkboxGroup":
      return (
        <>
          {values[x.name] !== undefined && (
            <GroupCheckbox formik={formik} x={x} />
          )}
        </>
      );
    case "checkbox":
      return <CheckboxField formik={formik} x={x} />;

    case "radio":
      return (
        <FormControl
          sx={{
            marginRight: x.mr,
            minWidth: x.width || "100%",
            maxWidth: x.width || "100%",
            marginTop: x.mt,
          }}
        >
          <FormLabel>{t(x.label as string)}</FormLabel>
          <RadioGroup
            name={x.name}
            value={formik.values[x.name]}
            row
            sx={{ justifyContent: "space-between" }}
          >
            {x.data.map(
              (radio: { label: string; value: string }, idx: number) => (
                <FormControlLabel
                  key={idx}
                  disabled={disabled}
                  value={radio.value}
                  checked={formik.values[x.name] === radio.value}
                  control={<Radio />}
                  onChange={(event: any) => {
                    formik.setFieldValue(x.name, event.target.value);
                  }}
                  label={t(radio.label)}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
      );

    case "select":
      return (
        <FormControl
          sx={{
            marginRight: x.mr,
            minWidth: x.width || "100%",
            maxWidth: x.width || "100%",
          }}
        >
          <SelectField x={x} formik={formik} />
        </FormControl>
      );

    case "switch":
      return (
        <FormControl
          sx={{
            marginRight: x.mr,
            minWidth: x.width || "100%",
            maxWidth: x.width || "100%",
          }}
        >
          <FormControlLabel
            disabled={x?.disabled}
            control={
              <Switch
                checked={values[x.name]}
                onChange={() =>
                  x?.cb ? x?.cb() : setFieldValue(x.name, !values[x.name])
                }
              />
            }
            label={t(x.label as string)}
          />
        </FormControl>
      );

    case "multiSelect":
      return (
        <>
          {values && <MultiSelectField formik={formik} x={x} name={x.name} />}
        </>
      );
    case "password":
      return (
        <FormControl sx={{ marginRight: x.mr, minWidth: x.width }}>
          <FieldPassword
            formik={formik}
            values={values}
            x={x}
            setFieldValue={setFieldValue}
          />
        </FormControl>
      );
    case "slider":
      return (
        <>
          {values[x.name] !== undefined && (
            <SliderRange formik={formik} x={x} />
          )}
        </>
      );
    case "date":
      return <DatePicker formik={formik} x={x} />;
    default:
      if (_.has(x, "display") && !x.display) {
        return <></>;
      }
      return (
        <TextFieldInput
          checkOnChangeField={checkOnChangeField}
          isFetchingErrors={isFetchingErrors}
          formik={formik}
          x={x}
        />
      );
  }
};

export default FormFieldType;
