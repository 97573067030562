import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage  } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyCBh1jmYKMx66ToPn9lMxkCHxPx48N02N8",
    authDomain: "novum-3ea3c.firebaseapp.com",
    projectId: "novum-3ea3c",
    storageBucket: "novum-3ea3c.appspot.com",
    messagingSenderId: "119528859131",
    appId: "1:119528859131:web:956091474c17ff95f2975e",
    measurementId: "G-8GL6HQL89C"
};
export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return navigator.serviceWorker.register(
                    '/firebase-messaging-sw.js',
                    {
                        scope: '/firebase-push-notification-scope',
                    }
                );
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
    getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
        getToken(messaging, {
            vapidKey:
                'BMoV6slrdHXCfWRYma4UWZ_wH6AkjMJIVKUhXeYGLHSybgVBX1Z-_63N5MKC_Z1YX99xu0AICHYZfA05DpC-CYQ',
            serviceWorkerRegistration,
        })
    );

export const onForegroundMessage = () => {
    return new Promise((resolve) =>
        onMessage(messaging, (payload) => resolve(payload))
    );
};
