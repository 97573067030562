import compose from "compose-function";

import { withDayJS } from "./with-dayjs";
import { withMui } from "./with-mui-theme";
import { withStyledComponents } from "./with-styled-components";
import { withTranslationProvider } from "./with-translation";

export const withProviders = compose(
  withMui,
  withStyledComponents,
  withTranslationProvider,
  withDayJS
);
