import { configureStore } from "@reduxjs/toolkit";
import {
  rolesApi,
  usersApi,
  dataApi,
  paxfulAccountApi,
  fakeCustomerApi,
  gatewaysApi,
  offersApi,
  reportsApi,
} from "shared/api/services";
import { meApi } from "shared/api/services/MeService";

import { rootReducer } from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      usersApi.middleware,
      offersApi.middleware,
      rolesApi.middleware,
      dataApi.middleware,
      meApi.middleware,
      paxfulAccountApi.middleware,
      fakeCustomerApi.middleware,
      gatewaysApi.middleware,
      reportsApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
