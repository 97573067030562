import { InputAdornment, TextareaAutosize } from "@mui/material";

import { FieldType } from "../index";

export interface IMessagingFields {
  createMessage: FieldType[];
  columns: FieldType[];
  filter: FieldType[];
}

const columnFields = [
  {
    id: "0123",
    name: "all",
    label: "COLUMNS_ALL",
    width: "33.33333333333%",
    type: "checkbox",
  },
  {
    id: 1,
    name: "label",
    label: "COLUMNS_LABEL",
    width: "33.33333333333%",
    type: "checkbox",
  },
  {
    id: 2,
    name: "status",
    label: "COLUMNS_STATUS",
    type: "checkbox",
    width: "33.3333333333%",
  },
  {
    id: 3,
    name: "messageType",
    label: "FORM_FIELD_MESSAGE_TYPE",
    type: "checkbox",
    width: "33.3333333333%",
  },
  {
    id: 4,
    name: "message",
    label: "FORM_FIELD_MESSAGE",
    type: "checkbox",
    width: "33.3333333333%",
  },
  {
    id: 5,
    name: "paymentMethodType",
    label: "FORM_FIELD_PAYMENT_METHOD_TYPE",
    type: "checkbox",
    width: "33.3333333333%",
  },
  {
    id: 6,
    name: "paymentMethods",
    label: "FORM_FIELD_PAYMENT_METHOD",
    type: "checkbox",
    width: "33.3333333333%",
  },
];

export const messageTypes = [
  { name: "MESSAGING_MESSAGE_TYPE_OPENING", id: "open" },
  { name: "No user activity", id: "no_activity" },
  { name: "Cancelled Buyer [Sell]", id: "cancel_buyer" },
  { name: "Cancelled System [Sell]", id: "cancel_system" },
  {
    name: "Feedback Positive After Release",
    id: "feedback_after_release",
  },
  { name: "Conversation", id: "conversation" },
];

export const fields: IMessagingFields = {
  createMessage: [
    {
      id: 1,
      label: "FORM_FIELD_LABEL",
      name: "label",
      type: "text",
      variant: "standard",
      required: true,
    },
    {
      id: 2,
      label: "FORM_FIELD_PAYMENT_METHOD_TYPE",
      name: "paymentMethodType",
      type: "radio",
      width: "100%",
      data: [
        {
          label: "MESSAGING_FORM_FIELD_PAYMENT_METHOD_TYPE_ALL",
          value: "all",
        },
        {
          label: "MESSAGING_FORM_FIELD_PAYMENT_METHOD_TYPE_WHITELIST",
          value: "whitelist",
        },
        {
          label: "MESSAGING_FORM_FIELD_PAYMENT_METHOD_TYPE_BLACKLIST",
          value: "blacklist",
        },
      ],
    },
    {
      id: 3,
      label: "FORM_FIELD_PAYMENT_METHOD",
      name: "paymentMethods",
      fetchUrl: "/offers/payment-method",
      type: "multiSelect",
      placeholder: "FORM_FIELD_PAYMENT_METHOD",
    },
    {
      id: 4,
      label: "FORM_FIELD_SWITCH_ACTIVE",
      name: "status",
      type: "switch",
      width: "48.5%",
    },
    {
      id: 5,
      label: "FORM_FIELD_MESSAGE_TYPE",
      name: "messageType",
      type: "select",
      width: "100%",
      data: messageTypes,
      required: true,
    },
    {
      id: 6,
      label: "FORM_FIELD_TIMER",
      name: "messageRepeatTime",
      required: true,
      InputProps: {
        endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
      },
      type: "text",
      variant: "standard",
    },
    {
      id: 7,
      label: "Message",
      name: "message",
      type: "text",
      InputProps: {
        inputComponent: TextareaAutosize,
      },
      required: true,
    },
  ],
  columns: columnFields,
  filter: [
    {
      id: 1,
      label: "FORM_FIELD_MESSAGE_TYPE",
      required: true,
      name: "messageType",
      type: "multiSelect",
      localData: [
        { title: "Openning message", id: "open" },
        { title: "No user activity", id: "no_activity" },
        { title: "Cancelled Buyer [Sell]", id: "cancel_buyer" },
        { title: "Cancelled System [Sell]", id: "cancel_system" },
        {
          title: "Feedback Positive After Release",
          id: "feedback_after_release",
        },
        { title: "Conversation", id: "conversation" },
      ],
    },
    {
      id: 2,
      label: "FORM_FIELD_PAYMENT_METHOD",
      mt: 3,
      name: "paymentMethods",
      fetchUrl: "/offers/payment-method",
      type: "multiSelect",
      placeholder: "Payment Methods",
    },
  ],
};

interface IHeadColumns {
  all: boolean;
  label: boolean;
  status: boolean;
  messageType: boolean;
  message: boolean;
  paymentMethodType: boolean;
  paymentMethods: boolean;
  actions: boolean;
}

export const headColumns: IHeadColumns = {
  all: true,
  label: true,
  status: true,
  messageType: true,
  message: true,
  paymentMethodType: true,
  paymentMethods: true,
  actions: true,
};

interface IInitialValues {
  createMessage: {
    label: string;
    paymentMethodType: string;
    paymentMethods: Array<string>;
    status: boolean;
    messageType: string;
    messageRepeatTime: string;
    message: string;
  };
  filter?: any;
  columns: IHeadColumns;
}

export const initialValues: IInitialValues = {
  createMessage: {
    label: "",
    paymentMethodType: "all",
    paymentMethods: [],
    messageRepeatTime: "",
    status: false,
    messageType: "no_activity",
    message: "",
  },
  filter: {
    messageType: [],
    paymentMethods: [],
  },
  columns: headColumns,
};
