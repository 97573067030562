import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, SxProps } from "@mui/material";
import { Breakpoint } from "@mui/system";
import React from "react";

import { SDialogContent, SDialogDescription, SDialogTitle } from "./constants";

interface ISimpleDialogProps {
  open: boolean;
  handleClose: any;
  title: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  sx?: SxProps;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | false;
  withoutIconClose?: boolean | undefined;
  withoutEsc?: boolean;
}

const SimpleDialog: React.FC<ISimpleDialogProps> = ({
  open,
  handleClose,
  title,
  description,
  children,
  sx,
  maxWidth,
  withoutIconClose,
  withoutEsc,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={withoutEsc ? () => {} : handleClose}
      sx={sx}
      maxWidth={maxWidth}
    >
      <Box sx={{ m: 0, p: 2 }}>
        {title && <SDialogTitle>{title}</SDialogTitle>}
        {description && <SDialogDescription>{description}</SDialogDescription>}
        {handleClose && !withoutIconClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <SDialogContent>{children}</SDialogContent>
    </Dialog>
  );
};

export default SimpleDialog;
