import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormikProps } from "formik";
import React from "react";

// import { useLocation } from "react-router-dom";
import { FieldType } from "../../constants";
import {useTranslation} from "react-i18next";

interface IProps {
  x: FieldType;
  formik: FormikProps<any>;
}

// const banAllChecked = {};

const CheckboxField: React.FC<IProps> = ({ x, formik }) => {
  // const { pathname } = useLocation();
  const { values, setFieldValue } = formik;
  const { t } = useTranslation("common");

  const onChange = (checked: boolean) => {
    if (x.name === "all") {
      Object.keys(values)?.forEach((checkbox) =>
        setFieldValue(checkbox, checked)
      );
    } else {
      setFieldValue(x.name, !values[x.name]);
    }
  };
  const isAllChecked = Object.keys(values).every((x) => values[x]);
  return (
    <>
      {values[x.name] !== undefined && (
        <FormControl sx={{ marginRight: x.mr, minWidth: x.width }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  x.name === "all" ? isAllChecked : Boolean(values[x.name])
                }
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label={t(x.label || "-")}
          />
        </FormControl>
      )}
    </>
  );
};

export default CheckboxField;
