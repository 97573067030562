import { ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

export const SLeft = styled("div")`
  padding-left: 40px;
`;

export const SListItemButton = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(25, 118, 210, 0.08) !important",
    },
    "&$selected:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.08) !important",
    },
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.08) !important",
    },
  },
})(ListItemButton);
