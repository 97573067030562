import AddCardIcon from "@mui/icons-material/AddCard";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DifferenceIcon from "@mui/icons-material/Difference";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import GroupIcon from "@mui/icons-material/Group";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import StackedBarChartRoundedIcon from "@mui/icons-material/StackedBarChartRounded";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import React from "react";

export const PRIVATE_MODULES = {
  users_management: "users-management",
  offers: "offers",
  trades: "trades",
  reports: "reports",
  paxful_users: "external-users-statistics",
  execution_transactions: "execution_transactions",
  fake_customers: "fake-customers",
  assign_trade: "assign-trade",
  roles_management: "roles-management",
  accounts_management: "external-accounts-management",
  dashboard: "dashboard",
  offer_competition: "offer-competition",
  auto_messaging: "auto-messaging",
  transactions: "transactions",
  paxful_account_management: "paxful_account_management",
  users_statistics: "users-statistics",
  gateways: "gateways",
  "custom-trade": "custom-trade",
};

export interface IPage {
  path: string;
  element: React.ReactNode;
  subpages?: Array<IPage>;
  icon?: React.ReactNode;
  name: string;
}

export const PAGE_ROUTES = {
  dashboard: "/",
  trades: "/trades",
  transactions: "/transactions",
  reports: "/reports",
  messaging: "/auto-messaging",
  offers: "/offers",
  offerCompetition: "/offer-competition",
  userStatistics: "/user-statistics",
  paxfulUserStatistics: "/user-statistics/paxful",
  novumUserStatistics: "/user-statistics/novum",
  fakeCustomers: "/fake-customers",
  admin: "/admin",
  adminUsers: "/admin/users",
  adminRoles: "/admin/roles",
  adminAccountManagement: "/admin/account-management",
  adminGateways: "/admin/gateways",
  auth: "/auth",
  "custom-trades": "/custom-trades",
};

const pagesAdmin = [
  {
    path: PAGE_ROUTES.dashboard,
    element: "PAGE_NAMES_DASHBOARD",
    icon: <DashboardIcon />,
    name: PRIVATE_MODULES.dashboard,
  },
  {
    path: PAGE_ROUTES.trades,
    element: "PAGE_NAMES_TRADES",
    icon: <StackedBarChartRoundedIcon />,
    name: PRIVATE_MODULES.trades,
  },
  {
    path: PAGE_ROUTES["custom-trades"],
    element: "Custom Trades",
    icon: <StackedBarChartRoundedIcon />,
    name: PRIVATE_MODULES["custom-trade"],
  },
  {
    path: PAGE_ROUTES.transactions,
    element: "PAGE_NAMES_TRANSACTIONS",
    icon: <SyncAltIcon />,
    name: PRIVATE_MODULES.transactions,
  },
  {
    path: PAGE_ROUTES.reports,
    element: "PAGE_NAMES_REPORTS",
    icon: <TextSnippetIcon />,
    name: PRIVATE_MODULES.reports,
  },
  {
    path: PAGE_ROUTES.messaging,
    element: "PAGE_NAMES_MESSAGING",
    icon: <MailOutlineIcon />,
    name: PRIVATE_MODULES.auto_messaging,
  },
  {
    path: PAGE_ROUTES.offers,
    element: "PAGE_NAMES_OFFERS",
    icon: <DifferenceIcon />,
    name: PRIVATE_MODULES.offers,
  },
  {
    path: PAGE_ROUTES.offerCompetition,
    element: "PAGE_NAMES_OFFER_COMPETITION",
    icon: <CasesRoundedIcon />,
    name: PRIVATE_MODULES.offer_competition,
  },
  {
    path: PAGE_ROUTES.userStatistics,
    element: "PAGE_NAMES_USER_STATISTICS",
    icon: <GroupIcon />,
    name: PRIVATE_MODULES.users_statistics,
    subpages: [
      {
        path: PAGE_ROUTES.paxfulUserStatistics,
        element: "PAGE_NAMES_PAXFUL",
        icon: <PersonIcon />,
        name: PRIVATE_MODULES.paxful_users,
      },
      {
        path: PAGE_ROUTES.novumUserStatistics,
        element: "PAGE_NAMES_NOVUM",
        icon: <PermContactCalendarIcon />,
        name: PRIVATE_MODULES.users_statistics,
      },
    ],
  },
  {
    path: PAGE_ROUTES.fakeCustomers,
    element: "PAGE_NAMES_FAKE_TRADERS",
    icon: <FaceRoundedIcon />,
    name: PRIVATE_MODULES.fake_customers,
  },
  {
    path: PAGE_ROUTES.admin,
    element: "PAGE_NAMES_ADMIN",
    icon: <GroupIcon />,
    name: PRIVATE_MODULES.users_management,
    subpages: [
      {
        path: PAGE_ROUTES.adminUsers,
        element: "PAGE_NAMES_USERS",
        icon: <PersonAddIcon />,
        name: PRIVATE_MODULES.users_management,
      },
      {
        path: PAGE_ROUTES.adminRoles,
        element: "PAGE_NAMES_ROLES",
        icon: <PersonPinIcon />,
        name: PRIVATE_MODULES.roles_management,
      },
      {
        path: PAGE_ROUTES.adminAccountManagement,
        element: "PAGE_NAMES_ACCOUNT_MANAGEMENT",
        icon: <SettingsSystemDaydreamIcon />,
        name: PRIVATE_MODULES.accounts_management,
      },
      {
        path: PAGE_ROUTES.adminGateways,
        element: "PAGE_NAMES_GATEWAYS",
        icon: <AddCardIcon />,
        name: PRIVATE_MODULES.gateways,
      },
    ],
  },
];
const pagesOtherRoles = [
  {
    path: PAGE_ROUTES.dashboard,
    element: "PAGE_NAMES_DASHBOARD",
    icon: <DashboardIcon />,
    name: PRIVATE_MODULES.dashboard,
  },
  {
    path: PAGE_ROUTES.trades,
    element: "PAGE_NAMES_TRADES",
    icon: <StackedBarChartRoundedIcon />,
    name: PRIVATE_MODULES.trades,
  },
  {
    path: PAGE_ROUTES["custom-trades"],
    element: "Custom Trades",
    icon: <StackedBarChartRoundedIcon />,
    name: PRIVATE_MODULES["custom-trade"],
  },
  {
    path: PAGE_ROUTES.transactions,
    element: "PAGE_NAMES_TRANSACTIONS",
    icon: <SyncAltIcon />,
    name: PRIVATE_MODULES.transactions,
  },
  {
    path: PAGE_ROUTES.reports,
    element: "PAGE_NAMES_REPORTS",
    icon: <TextSnippetIcon />,
    name: PRIVATE_MODULES.reports,
  },
  {
    path: PAGE_ROUTES.messaging,
    element: "PAGE_NAMES_MESSAGING",
    icon: <MailOutlineIcon />,
    name: PRIVATE_MODULES.auto_messaging,
  },
  {
    path: PAGE_ROUTES.offers,
    element: "PAGE_NAMES_OFFERS",
    icon: <DifferenceIcon />,
    name: PRIVATE_MODULES.offers,
  },
  {
    path: PAGE_ROUTES.offerCompetition,
    element: "PAGE_NAMES_OFFER_COMPETITION",
    icon: <CasesRoundedIcon />,
    name: PRIVATE_MODULES.offer_competition,
  },
  {
    path: PAGE_ROUTES.userStatistics,
    element: "PAGE_NAMES_USER_STATISTICS",
    icon: <GroupIcon />,
    name: PRIVATE_MODULES.users_statistics,
    subpages: [
      {
        path: PAGE_ROUTES.paxfulUserStatistics,
        element: "PAGE_NAMES_PAXFUL",
        icon: <PersonIcon />,
        name: PRIVATE_MODULES.paxful_users,
      },
      {
        path: PAGE_ROUTES.novumUserStatistics,
        element: "PAGE_NAMES_NOVUM",
        icon: <PermContactCalendarIcon />,
        name: PRIVATE_MODULES.users_statistics,
      },
    ],
  },
  {
    path: PAGE_ROUTES.fakeCustomers,
    element: "PAGE_NAMES_FAKE_TRADERS",
    icon: <FaceRoundedIcon />,
    name: PRIVATE_MODULES.fake_customers,
  },
  {
    path: PAGE_ROUTES.adminUsers,
    element: "PAGE_NAMES_USERS",
    icon: <PersonAddIcon />,
    name: PRIVATE_MODULES.users_management,
  },
  {
    path: PAGE_ROUTES.adminRoles,
    element: "PAGE_NAMES_ROLES",
    icon: <PersonPinIcon />,
    name: PRIVATE_MODULES.roles_management,
  },
  {
    path: PAGE_ROUTES.adminAccountManagement,
    element: "PAGE_NAMES_ACCOUNT_MANAGEMENT",
    icon: <SettingsSystemDaydreamIcon />,
    name: PRIVATE_MODULES.accounts_management,
  },
  {
    path: PAGE_ROUTES.adminGateways,
    element: "PAGE_NAMES_GATEWAYS",
    icon: <AddCardIcon />,
    name: PRIVATE_MODULES.gateways,
  },
];
export const PAGES = (isAdmin?: boolean) => {
  let newPages;
  if (isAdmin) {
    newPages = pagesAdmin;
  } else {
    newPages = pagesOtherRoles;
  }
  return newPages;
};
