import { meActions } from "app/entities/me/model";
import { dictionaryActions } from "app/entities/model/dictionaries";
import { usersActions } from "app/entities/model/users";
import {dataActions} from "../../entities/model/data";

const actions = {
  ...usersActions,
  ...dictionaryActions,
  ...dataActions,
  ...meActions,
};

export default actions;
