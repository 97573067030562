import HomeIcon from "@mui/icons-material/Home";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES } from "shared/constants/page";

import { useAppSelector } from "../../app/hooks";
import { hasModulePermission } from "../../shared/helpers";
import NavigationItem from "./ui/NavigationItem";
import { SListItemButton } from "./ui/NavigationItem/constants";

const SList: any = styled(List)`
  padding: 8px;
  .MuiListItem-root {
    height: ${(props: any) => (!props.open ? "50px" : "auto")};
    .MuiButtonBase-root {
      height: 100%;
    }
  }
`;

interface IProps {
  open?: boolean;
}
const Navigation = ({ open }: IProps) => {
  const { t } = useTranslation("common");
  const { role } = useAppSelector((state) => state.me);
  const navigate = useNavigate();
  const isAdmin = role?.type === "Admin";
  const { pathname } = useLocation();
  return (
    <SList open={open}>
      {sessionStorage.getItem("backToHome") &&
        !sessionStorage.getItem("subDomain") && (
          <ListItem disablePadding>
            <SListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                sessionStorage.clear();
                navigate("/");
                window.location.reload();
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {" "}
                <HomeIcon />
              </ListItemIcon>
              {open && (
                <ListItemText style={{ opacity: !open ? 0 : 1 }}>
                  Back to Home
                </ListItemText>
              )}
            </SListItemButton>
            {/*<SListItemButton*/}
            {/*  sx={{*/}
            {/*    minHeight: 48,*/}
            {/*    justifyContent: open ? "initial" : "center",*/}
            {/*    px: 2.5,*/}
            {/*    backgroundColor: "",*/}
            {/*  }}*/}

            {/*>*/}
            {/*  <ListItemIcon*/}
            {/*    sx={{*/}
            {/*      minWidth: 0,*/}
            {/*      mr: open ? 3 : "auto",*/}
            {/*      justifyContent: "center",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <HomeIcon />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText style={{ opacity: !open ? 0 : 1 }}>*/}
            {/*    Back to Home*/}
            {/*  </ListItemText>*/}
            {/*</SListItemButton>*/}
          </ListItem>
        )}
      {PAGES(isAdmin).map((pageRoute, index) => {
        const hasPermission = hasModulePermission(
          pageRoute?.name,
          role?.possibilities
        );
        //@ts-ignore
        const hasInnerPermission = pageRoute?.subpages?.reduce((acc, p) => {
          if (hasModulePermission(p.name, role?.possibilities)) {
            acc = true;
          }
          return acc;
        }, false);
        return (
          (hasPermission || hasInnerPermission) && (
            <NavigationItem
              key={index}
              open={open}
              currentPath={pathname}
              itemName={t(pageRoute.element as string)}
              icon={pageRoute?.icon}
              subItems={pageRoute?.subpages}
              link={pageRoute.path}
            />
          )
        );
      })}
    </SList>
  );
};

export default Navigation;
