import { Fields } from "features/form-field-type/config";
import FormFieldType from "features/form-field-type/ui";
import { FormikProps } from "formik";
import React from "react";
import { FieldType } from "shared/constants";
import styled from "styled-components";

export interface IFormConstructorProps {
  fields: Array<Fields>;
  columns?: number;
  formik: FormikProps<any>;
  formWidth?: number;
}
interface styledProps {
  width: number;
}

const FormWrapper = styled.form`
  max-width: ${({ width }: styledProps) => (width ? `${width}px` : "100%")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

function formConstructor(props: IFormConstructorProps) {
  const { fields, formWidth = 0, formik } = props;

  return (
    <FormWrapper width={formWidth}>
      {fields.map((x: FieldType, index) => (
        <React.Fragment key={x.id}>
          <FormFieldType x={x} formik={formik} />
        </React.Fragment>
      ))}
    </FormWrapper>
  );
}

export default formConstructor;
