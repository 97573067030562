import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { baseApi } from "../../xhr";

export const gatewaysApi = baseApi.injectEndpoints({
  endpoints: (build) =>
    ({
      editGateways: build.mutation({
        query: (body) => ({
          url: `/gateways:${body.id}`,
          method: "PATCH",
          body,
        }),
      }),
      createGateway: build.mutation({
        query: (body) => ({
          url: `/gateways`,
          method: "POST",
          body,
        }),
      }),
      fetchGatewaySettings: build.query({
        query: (params) => ({
          url: `/gateway-limits/get-detail/info`,
          method: "GET",
          params,
        }),
      }),
      removeGatewayCard: build.mutation({
        query: (body) => ({
          url: `/gateway-limits/delete/limits?card_id=${body.id}`,
          method: "DELETE",
          body,
        }),
      }),
      gatewaySettingsUpdate: build.mutation({
        query: (params) => ({
          url: `/gateway-limits/update/info`,
          method: "PATCH",
          params,
        }),
      }),
      addGatewayCards: build.mutation({
        query: (body) => ({
          url: `/gateway-limits/add/limits?gateway_id=${body.gateway_id}`,
          method: "POST",
          body,
        }),
      }),
    } as EndpointDefinitions),
});

export const {
  useEditGatewaysMutation,
  useFetchGatewaySettingsQuery,
  useRemoveGatewayCardMutation,
  useGatewaySettingsUpdateMutation,
  useAddGatewayCardsMutation,
  useCreateGatewayMutation,
}: any = gatewaysApi;
