import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import useAction from "../../../../../shared/hooks/useAction";

const ProccessChangeAccount = styled.div`
  text-align: center;
  div {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    font-size: 14px;
  }
`;

interface IProps {
  close: () => void;
}
const NoonesAccountActivate: React.FC<IProps> = ({ close }) => {
  const { pathname } = useLocation();
  const { setIsPaxfulNotifyOtherUsers } = useAction();

  useEffect(() => {
    let timeout: any;
    timeout = setTimeout(() => {
      if (pathname === "/admin/account-management") {
        setIsPaxfulNotifyOtherUsers();
      }
      close();
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <ProccessChangeAccount>
      <CircularProgress />
      <div>Paxful account change in progress. </div>
      <p>
        Information is being parsed from another account. Please do not close
        the window.
      </p>
    </ProccessChangeAccount>
  );
};

export default NoonesAccountActivate;
