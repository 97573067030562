import { CssBaseline } from "@mui/material";
import { Routing } from "pages";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "../shared/ui/Loader";
import { withProviders } from "./providers";
import { store } from "./store";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <CssBaseline />
          <ToastContainer autoClose={1000} />
          <Routing />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default withProviders(App);
