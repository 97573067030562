import { createSlice } from "@reduxjs/toolkit";
import {
  removeTokens,
  saveTokensToLocal,
} from "shared/api/xhr/helpers";

interface UsersState {
  accessToken: string | null;
  refreshToken: string | null;
  tokenType: string | null;
}

export const initialState: UsersState = {
  accessToken:   null,
  refreshToken: null,
  tokenType:   null,
};
export const usersModel = createSlice({
  name: "users",
  initialState,
  reducers: {
    saveToken: (state, { payload }) => {
      saveTokensToLocal(payload);
      state.accessToken = payload.access_token;
      state.refreshToken = payload.refresh_token;
      state.tokenType = payload.token_type;
    },
    removeToken: (state) => {
      removeTokens();
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenType = null;
    },
  },
});

const usersActions = usersModel.actions;
export { usersActions };

export default usersModel.reducer;
