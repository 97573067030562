import { createApi } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { axiosRequest } from "shared/api/xhr";

import { usersActions } from "../../../../app/entities/model/users";
import { ITokens } from "../constants";
import { getLocalTokens, saveTokensToLocal } from "../helpers";

const mutex = new Mutex();

const axiosBaseQuery = async ({ url, method, data, params }: any) => {
  try {
    const result = await axiosRequest({ url, method, data, params });
    return { data: result };
  } catch (axiosError: any) {
    return {
      error: axiosError.response,
    };
  }
};

export const baseQueryWithReAuth = async (args: any, api: any) => {
  await mutex.waitForUnlock();
  const currentArgs = { ...args, data: args.body };
  let result = await axiosBaseQuery(currentArgs);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const tokens = getLocalTokens();
        const refreshResult: ITokens = await axiosRequest.post(
          `/auth/refresh`,
          {
            refresh_token: tokens.refresh_token,
          }
        );
        if (refreshResult) {
          api.dispatch(usersActions.saveToken(refreshResult));
          saveTokensToLocal(refreshResult);
          result = await axiosBaseQuery(currentArgs);
        } else {
          api.dispatch(usersActions.removeToken());
        }
      } catch (e) {
        api.dispatch(usersActions.removeToken());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await axiosBaseQuery(currentArgs);
    }
  }
  return result;
};

export const baseQuery = baseQueryWithReAuth;
export const baseApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
