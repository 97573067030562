import styled from "@emotion/styled";
import { DialogContent } from "@mui/material";

export const SDialogContent = styled(DialogContent)`
  padding: 16px 24px 24px;
`;

export const SDialogTitle = styled("h2")`
  font-size: 24px;
  font-weight: 400;
  margin: 0;
`;

export const SDialogDescription = styled("p")`
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
`;