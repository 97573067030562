import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

export const withDayJS = (component: () => React.ReactNode) => () =>
  (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {component()}
    </LocalizationProvider>
  );
