import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import { I18nextProvider } from "react-i18next";

import enCommon from "../locales/en/common.json";
import ruCommon from "../locales/ru/common.json";

i18next
  // .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      ru: {
        common: ruCommon,
      },
      en: {
        common: enCommon,
      },
    },
    interpolation: { escapeValue: false },
    lng: "en",
    fallbackLng: "en",
    react: {
      useSuspense: false,
    },
  })
  .then((res) => res);

export const withTranslationProvider =
  (component: () => React.ReactNode) => () =>
    <I18nextProvider i18n={i18next}>{component()}</I18nextProvider>;
