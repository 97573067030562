import { createSlice } from "@reduxjs/toolkit";
import { meApi } from "shared/api/services/MeService";
import { IRole } from "shared/constants";

export interface IMeState {
  account_id: string;
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  _2fa: boolean;
  role: IRole | null;
}

const initialState: IMeState = {
  account_id: "",
  id: "",
  username: "",
  first_name: "",
  last_name: "",
  _2fa: false,
  role: null,
};

export const meModel = createSlice({
  name: "me",
  initialState,
  reducers: {
    updatMeAccountId: (state, { payload }) => {
      state.account_id = payload;
    },
    removeMe: (state) => {
      state.id = "";
      state.username = "";
      state.account_id = "";
      state.first_name = "";
      state.last_name = "";
      state._2fa = false;
      state.role = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        // @ts-ignore
        meApi.endpoints.fetchMe.matchFulfilled,
        (state, { payload }) => ({ ...state, ...payload })
      )
      .addMatcher(
        // @ts-ignore
        meApi.endpoints.updateMe.matchFulfilled,
        (state, { payload }) => ({ ...state, ...payload })
      );
  },
});

const meActions = meModel.actions;
export { meActions };

export default meModel.reducer;
