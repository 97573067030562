import drawerLogo from "assets/drawerLogo.svg";
import mobLogo from "assets/mob-logo.png";

import { SLayoutLogo } from "./constants";

const LayoutLogo = ({ open }: {open: boolean}) => {
  return (
    <SLayoutLogo open={open}>
      <img src={!open ? mobLogo : drawerLogo} alt="logo" />
    </SLayoutLogo>
  );
};

export default LayoutLogo;
