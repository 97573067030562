import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, SxProps, Theme } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IPage } from "shared/constants/page";

import { useAppSelector } from "../../../../app/hooks";
import { hasModulePermission } from "../../../../shared/helpers";
import { SLeft, SListItemButton } from "./constants";

interface INavigationItemProps {
  itemName: string;
  icon?: React.ReactNode;
  subItems?: Array<IPage>;
  link: string;
  sx?: SxProps<Theme>;
  open?: boolean;
  currentPath: string;
}

const NavigationItem: React.FC<INavigationItemProps> = ({
  itemName,
  icon,
  sx,
  subItems,
  link,
  open,
  currentPath,
}) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);

  const { role } = useAppSelector((state) => state.me);
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  return subItems?.length ? (
    <>
      <ListItem
        sx={open ? {} : { height: "50px" }}
        disablePadding
        className="sxe"
        onClick={handleClick}
      >
        <SListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            backgroundColor:
              currentPath === link ? "rgba(25, 118, 210, 0.08)" : "",
            px: 2.5,
            ...sx,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText style={{ opacity: !open ? 0 : 1 }} primary={itemName} />
          {open && <>{isOpen ? <ExpandLess /> : <ExpandMore />}</>}
        </SListItemButton>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map((item, index) => {
            const hasPermission = hasModulePermission(
              item?.name,
              role?.possibilities
            );
            return (
              hasPermission && (
                <NavigationItem
                  key={index}
                  open={open}
                  currentPath={currentPath}
                  icon={item?.icon}
                  itemName={t(item.element as string)}
                  sx={!open ? {} : { pl: 4 }}
                  link={item.path}
                />
              )
            );
          })}
        </List>
      </Collapse>
    </>
  ) : (
    <ListItem disablePadding>
      <SListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          backgroundColor:
            currentPath === link ? "rgba(25, 118, 210, 0.08)" : "",
          ...sx,
        }}
        onClick={() => navigate(link)}
      >
        {icon ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
        ) : (
          <SLeft />
        )}
        <ListItemText style={{ opacity: !open ? 0 : 1 }} primary={itemName} />
      </SListItemButton>
    </ListItem>
  );
};

export default NavigationItem;
