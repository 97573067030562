import NoonesAccountActivate from "features/table-constructor/ui/TableModals/NoonesAccountActivate";
import React from "react";
import { SimpleDialog } from "shared/ui";

interface IProps {
  open: boolean;
  close: () => void;
}

const PaxfulActivate: React.FC<IProps> = ({ open, close }) => {
  return (
    <SimpleDialog title="" open={open} handleClose={close}>
      <NoonesAccountActivate close={close} />
    </SimpleDialog>
  );
};

export default PaxfulActivate;
