import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SortIcon from "@mui/icons-material/Sort";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import dayjs from "dayjs";
import _ from "lodash";
import { FieldType } from "shared/constants";

import { BASE_URL } from "../../shared/config";
import { columnsParseQuery, offerTypeTitle } from "../../shared/helpers";

export interface IIcons {
  type: string;
  title: string;
  icon: any;
  isPopover?: boolean;
}
const rolesIcons: IIcons[] = [
  {
    type: "createRole",
    title: "CREATE_ROLE",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "FILTER",
    icon: FilterAltIcon,
  },
  {
    type: "view",
    title: "Table View",
    isPopover: true,
    icon: RemoveRedEyeIcon,
  },
];

export const usersIcons: IIcons[] = [
  {
    type: "addUser",
    title: "FAKE_CUSTOMER_CREATE_USER",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
];

export const paxfullAccountIcons = [
  {
    type: "createPaxfulAccount",
    title: "Create Noones Account",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
];

export const messagingIcons: IIcons[] = [
  {
    type: "createMessage",
    title: "Create Message",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
  {
    type: "csv",
    title: "",
    icon: FilePresentRoundedIcon,
  },
];

export const reportsIcons: IIcons[] = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
  {
    type: "csv",
    title: "",
    icon: FilePresentRoundedIcon,
  },
];

export const fakeCustomerIcons = [
  {
    type: "fakeCustomer",
    title: "FAKE_CUSTOMER_CREATE_TITLE",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "csv",
    title: "",
    icon: FilePresentRoundedIcon,
  },
];

export const gatewaysIcons = [
  {
    type: "editGateways",
    title: "Create gateway",
    icon: AddIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
];

export const userStatisticsIcons = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
];

const offersIcons = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
];

const offersCompetitionIcons = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
];

const tradersIcons = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
];
const transactionsIcons = [
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "columns",
    title: "TABLE_CONFIGS_COLUMNS",
    icon: ViewWeekIcon,
  },
  {
    type: "view",
    title: "",
    icon: RemoveRedEyeIcon,
  },
  {
    type: "csv",
    title: "",
    icon: FilePresentRoundedIcon,
  },
];

const novumUserStatistics = [
  {
    type: "sort",
    title: "",
    icon: SortIcon,
  },
  {
    type: "calendar-range",
    title: "",
    icon: CalendarTodayIcon,
  },
  {
    type: "selected-user",
    title: "",
    icon: PersonSearchIcon,
  },
];

export const createTradeIcons: IIcons[] = [
  {
    type: "createTrade",
    title: "Create Trade",
    icon: AddIcon,
  },
  {
    type: "filter",
    title: "TABLE_CONFIGS_FILTERS",
    icon: FilterAltIcon,
  },
  {
    type: "reset",
    title: "",
    icon: RestartAltIcon,
  },
];

const tableIcons = {
  novum: novumUserStatistics,
  users: usersIcons,
  roles: rolesIcons,
  "fake-customers": fakeCustomerIcons,
  "account-management": paxfullAccountIcons,
  "offer-competition": offersCompetitionIcons,
  gateways: gatewaysIcons,
  paxful: userStatisticsIcons,
  reports: reportsIcons,
  offers: offersIcons,
  "auto-messaging":
    sessionStorage.getItem("accountId") ===
    "caa6d75c-9592-4c56-9332-d7484e4d455f"
      ? []
      : messagingIcons,
  trades: tradersIcons,
  transactions: transactionsIcons,
  "custom-trades": createTradeIcons,
};

export const icons = (path: string) => {
  // @ts-ignore
  return tableIcons[path.split("/").at(-1)];
};

export const initialValues = {
  usersManagement: {
    addUser: [
      {
        id: 1,
        label: "Username",
        name: "username",
        variant: "standard",
        width: "100%",
      },
      {
        id: 2,
        label: "First Name",
        name: "firstName",
        variant: "standard",
        width: "49.5%",
        mr: "1%",
      },
      {
        id: 3,
        label: "Last Name",
        name: "lastName",
        variant: "standard",
        width: "49.5%",
      },
      {
        id: 4,
        label: "Password",
        name: "password",
        type: "password",
        variant: "standard",
        width: "49.5%",
        mr: "1%",
      },
      {
        id: 5,
        label: "Confirm password",
        name: "confirmPassword",
        type: "password",
        variant: "standard",
        width: "49.5%",
      },
      {
        id: 6,
        label: "Role",
        name: "role",
        type: "select",
        variant: "standard",
        width: "100%",
      },
    ],
  },
};

// const dates = (from: string, to: string) => {
//   return {
//     toDate: "",
//   };
// };

export const generateParams = (obj: any, path: string, search: string) => {
  switch (path) {
    case "/custom-trades":
      return {
        search: search || undefined,
        createdBy: obj.filter?.createdBy || undefined,
        fromDate: obj.filter?.fromDate
          ? dayjs(obj.filter?.fromDate).format("YYYY-MM-DD")
          : undefined,
        toDate: obj.filter?.toDate
          ? dayjs(obj.filter?.toDate).format("YYYY-MM-DD")
          : undefined,
        source: obj.filter?.sourceSocial || undefined,
        status: Object.keys(obj.filter?.tradeStatuses || {})
          .filter((item) => Boolean(obj.filter?.tradeStatuses[item]))
          .map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
          .join(","),
      };
    case "/user-statistics/novum":
      return {
        search: search || undefined,
        from_date: obj["calendar-range"]
          ? dayjs(obj["calendar-range"]?.value[0]).format("YYYY-MM-DD")
          : undefined,
        to_date: obj["calendar-range"]
          ? dayjs(obj["calendar-range"]?.value[1]).format("YYYY-MM-DD")
          : undefined,
        trades_count: obj?.sort?.tradesCount || undefined,
        avg_time: obj?.sort?.averageTime || undefined,
        successful: obj?.sort?.success || undefined,
        users: obj["selected-user"]
          ? obj["selected-user"].users?.map((x: number) => x).join(",")
          : undefined,
      };
    case "/trades":
      return {
        search: search || undefined,
        amount: _.isEqual(obj.filter.amount, [0, 0])
          ? undefined
          : obj.filter.amount.map((x: number) => x).join(","),
        offerType: obj.filter.offerType || undefined,
        paymentMethods:
          obj.filter.paymentMethod.map((x: FieldType) => x.name).join(",") ||
          undefined,
        crypto:
          Object.keys(obj.filter.crypto)
            .filter((x) => obj.filter.crypto[x])
            .map((el) => el.toUpperCase())
            .join(",") || undefined,
        currency:
          obj.filter.currency.map((x: FieldType) => x.code).join(",") ||
          undefined,
        assigned: obj.filter.assignedTo || undefined,
        status: obj.filter.tradeStatus || undefined,
        toDate: obj.filter.toDate
          ? dayjs(obj.filter.toDate).format("YYYY-MM-DD")
          : undefined,
        fromDate: obj.filter.fromDate
          ? dayjs(obj.filter.fromDate).format("YYYY-MM-DD")
          : undefined,
        columns: columnsParseQuery(obj),
      };
    case "/reports":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
        paymentMethods:
          obj.filter.paymentMethods?.map((x: FieldType) => x.name).join(",") ||
          undefined,
        traders: obj.filter.traders
          ?.map((item: { partner: string }) => item.partner)
          .join(","),
        fromDate: obj.filter.fromDate
          ? dayjs(obj.filter.fromDate).format("YYYY-MM-DD")
          : undefined,
        toDate: obj.filter.toDate
          ? dayjs(obj.filter.toDate).format("YYYY-MM-DD")
          : undefined,
      };
    case "/transactions":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
        paymentMethods:
          obj.filter.paymentMethods?.map((x: FieldType) => x.name).join(",") ||
          undefined,
        source:
          obj.filter.source?.map((x: FieldType) => x.title).join(",") ||
          undefined,
        fromDate: obj.filter.fromDate
          ? dayjs(obj.filter.fromDate).format("YYYY-MM-DD")
          : undefined,
        toDate: obj.filter.toDate
          ? dayjs(obj.filter.toDate).format("YYYY-MM-DD")
          : undefined,
        currency:
          obj.filter.currency
            ?.map((x: FieldType) => x.code?.toUpperCase())
            .join(",") || undefined,
        hasChargeback: obj.filter.hasChargeback || undefined,
        remainingBalance: obj.filter.remainingBalance || undefined,
      };
    case "/fake-customers":
      return {
        search: search || undefined,
      };
    case "/admin/roles":
      return {
        possibilities:
          obj.filter.map((x: FieldType) => x.name).join(",") || undefined,
        search,
      };
    case "/admin/users":
      return {
        search: search || undefined,
        roles: obj.filter.map((x: FieldType) => x.id).join(",") || undefined,
        columns: columnsParseQuery(obj),
      };
    case "/admin/account-management":
      return {
        search: search || undefined,
      };
    case "/offer-competition":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
      };
    case "/offers":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
        paymentMethod:
          obj.filter.paymentMethod.map((x: FieldType) => x.name).join(",") ||
          undefined,
        crypto:
          obj.filter.crypto.map((x: FieldType) => x.code).join(",") ||
          undefined,
        currency:
          obj.filter.currency.map((x: FieldType) => x.code).join(",") ||
          undefined,
        margin: _.isEqual(obj.filter.margin, [0, 0])
          ? undefined
          : obj.filter.margin.map((x: number) => x).join(","),
        min: obj.filter.min || undefined,
        max: obj.filter.max || undefined,
        offerType:
          offerTypeTitle[obj.filter.offerType as keyof typeof offerTypeTitle] ||
          undefined,
      };
    case "/auto-messaging":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
        paymentMethods:
          obj.filter.paymentMethods?.map((x: FieldType) => x.name).join(",") ||
          undefined,
        messageType:
          obj.filter.messageType?.map((x: FieldType) => x.id).join(",") ||
          undefined,
      };
    case "/user-statistics/paxful":
      return {
        search: search || undefined,
        columns: columnsParseQuery(obj),
        is_blocked:
          Boolean(
            (obj.filter || []).find(
              (x: { title: string; id: number }) => x.title === "Is blocked"
            )
          ) || undefined,
        is_trusted:
          Boolean(
            (obj.filter || []).find(
              (x: { title: string; id: number }) => x.title === "Is trusted"
            )
          ) || undefined,
      };
  }
};

export const tableStructure = ({
  path,
  headings,
  body,
  qty,
  roleType,
  activeTab,
}: {
  path: string;
  headings: any;
  body: any;
  qty: number;
  roleType: string;
  activeTab: string;
}) => {
  switch (path) {
    case "/custom-trades":
      return {
        head: {
          vendor: "Vendor",
          source: "Source",
          createdBy: "Created By",
          assigned: "Assigned",
          tradeStatus: "Trade status",
          date: "Date",
          paymentCards: "Payment Cards",
          currency: "Currency",
          actions: "CUSTOM_TRADES_ACTION",
        },
        body: body.map((item: any) => {
          return {
            ...item,
            date: item.createdAt,
            tradeStatus: item.customTradeStatus,

            createdBy: item?.created_by?.username,
            assigned: item?.assigned_user?.username,
          };
        }),
        qty,
      };
    case "/user-statistics/novum":
      return {
        head: {
          "#": "#",
          username: "Username",
          firstName: "First Name",
          lastName: "Last Name",
          tradesCount: "Trades Count",
          avgTime: "Avg. Time, Sec",
          paymentMethods: "Payment Methods",
          status: "Status",
        },
        body: body.map(
          (
            item: { successfulQty: number; cancelledQty: number },
            index: number
          ) => ({
            ...item,
            "#": index + 1,
            status: {
              success: {
                percent: item.successfulQty
                  ? Math.round(
                      (item.successfulQty * 100) /
                        (item.successfulQty + item.cancelledQty)
                    )
                  : 0,
                count: item.successfulQty,
              },
              cancel: {
                percent: item.cancelledQty
                  ? Math.round(
                      (item.cancelledQty * 100) /
                        (item.successfulQty + item.cancelledQty)
                    )
                  : 0,
                count: item.cancelledQty,
              },
            },
          })
        ),
        qty,
      };
    case "/transactions":
      return {
        head: { ...headings, actions: "SCREENSHOT_AND_BALANCE" },
        body,
        qty,
      };
    case "/trades":
      let tradeHead: any = {
        details: "TRADES_FIELD_DETAILS",
        tradeHash: "TRADES_FIELD_HASH",
        name: "TRADES_FIELD_NAME",
        assigned: "TRADES_FIELD_ASSIGNED",
        status: "TRADES_FIELD_ORDER_STATUS",
        trader: "TRADES_FIELD_TRADER",
        dollar: "TRADES_FIELD_DOLLARS",
        crypto: "TRADES_FIELD_CRYPTO",
        currency: "TRADES_FIELD_CURRENCY",
        lifetimeFiat: "TRADES_FIELD_LIFETIME_FIAT",
        lifetimeCrypto: "TRADES_FIELD_LIFETIME_BTC",
        paymentMethod: "TRADES_FIELD_PAYMENT_METHOD",
        offerType: "TRADES_FIELD_OFFER",
        today: "TRADES_FIELD_TODAY",
        elapsed: "TRADES_FIELD_ELAPSED",
        created: "TRADES_FIELD_CREATED",
        duplicates: "TRADES_FIELD_DUPLICATES",
        actions: "State",
      };
      if (activeTab !== "active") {
        delete tradeHead["actions"];
      }
      return {
        head: tradeHead,
        body: body.map((el: any) => ({
          ...el,
          paymentMethod: el.paymentMethod || el.payment_method,
          assigned: el?.assigned_user?.username || "",
          created: el?.startedAt.replace("T", " "),
          today: el?.today || 0,
          dollar: el?.fiatAmountRequested || 0,
        })),
        qty,
      };
    case "/offers":
      return {
        head: headings,
        body,
        qty,
      };
    case "/offer-competition":
      return {
        head: {
          offerHash: headings.offerHash,
          crypto: headings.crypto,
          paymentMethod: headings.paymentMethod,
          status: headings.status,
          compete: headings.compete,
          competingOffers: headings.competingOffers,
        },
        body,
      };
    case "/auto-messaging":
      if (
        sessionStorage.getItem("accountId") ===
        "caa6d75c-9592-4c56-9332-d7484e4d455f"
      ) {
        return { head: {}, body: [] };
      }
      return {
        head: { ...headings, actions: "REMOVE_AND_EDIT" },
        qty,
        body,
      };
    case "/fake-customers":
      const makeAddressRow = (obj: any) => {
        return Object.keys(obj).reduce((accum, key) => {
          accum += obj[key] ? `${obj[key]}, ` : "";
          return accum;
        }, "");
      };
      const heads = {
        first_name: "FAKE_CUSTOMER_FIRST_NAME",
        last_name: "FAKE_CUSTOMER_LAST_NAME",
        email: "FAKE_CUSTOMER_EMAIL",
        phone_number: "FAKE_CUSTOMER_PHONE_NUMBER",
        address: "FAKE_CUSTOMER_ADDRESS",
        actions: "REMOVE_AND_EDIT",
      };
      return {
        head: {
          "#": "#",
          ...heads,
        },
        qty,
        body: body.map((el: any, idx: number) => {
          for (let key in el) {
            if (el[key] === null) {
              el[key] = "";
            }
          }
          return {
            ...el,
            address: makeAddressRow({
              country: el?.country,
              state: el?.state,
              city: el?.city,
              zip_code: el?.zip_code,
              address_1: el?.address_1,
              address_2: el?.address_2,
            }),
            "#": idx + 1,
          };
        }),
      };
    case "/reports":
      if (
        sessionStorage.getItem("accountId") ===
        "caa6d75c-9592-4c56-9332-d7484e4d455f"
      ) {
        headings["actions"] = "ONLY_EDIT";
        headings = { source: "Source", ...headings };
        delete headings["hash"];
        delete headings["cryptoFee"];
        delete headings["feeFiat"];
      }
      return {
        head: headings,
        body,
        qty,
      };
    case "/admin/gateways":
      const gatewaysHeads = {
        payment: "GATEWAYS_FIELD_LABEL",
        gateway: "GATEWAYS_FIELD_GATEWAY",

        name: "Account name",
        api_key: "GATEWAYS_FIELD_API_KEY",
        api_secret: "GATEWAYS_FIELD_API_SECRET",
        is_valid: "GATEWAYS_FIELD_VALID",
        actions: "EDIT_AND_SETTINGS",
      };
      return {
        head: {
          "#": "#",
          ...gatewaysHeads,
        },
        qty,
        body: body.map((el: any, idx: number) => {
          for (let key in el) {
            if (el[key] === null) {
              el[key] = "-";
            }
          }
          return {
            ...el,
            "#": idx + 1,
          };
        }),
      };
    case "/admin/roles":
      return {
        head: {
          index: "#",
          name: "Name",
          type: "Type",
          possibilities: "Possibilities",
          actions: headings.actions,
        },
        qty,
        body: body.map((el: FieldType, idx: number) => ({
          ...el,
          index: idx + 1,
        })),
      };
    case "/admin/users":
      return {
        head: {
          "#": "#",
          username: headings.username,
          firstName: headings.firstName,
          lastName: headings.lastName,
          role: headings.role,
          actions: headings.actions,
        },
        body: body.map((item: any, idx: number) => ({
          ...item,
          "#": idx + 1,
        })),
        qty,
      };
    case "/admin/account-management":
      let newHead: any = {
        "#": "#",
        key_name: "Key Name",
        prefix: "Prefix",
        key: "Key",
        secret: "Secret",
        webhook: "Webhook",
        is_active: "Active",
        actions: "REMOVE_AND_EDIT",
      };
      if (roleType !== "Admin") {
        delete newHead["webhook"];
      }

      return {
        head: newHead,
        body: body.map((el: FieldType, idx: number) => ({
          ...el,
          prefix: el.account_type,
          webhook: `${BASE_URL}/webhook?account_id=${el.id}`,
          "#": idx + 1,
        })),
      };
    case "/user-statistics/paxful":
      return {
        head: {
          ...headings,
        },
        qty,
        body,
      };
    default:
      return {
        head: headings,
        body,
      };
  }
};

export type getKeyFromFilterType = {
  [key: string]: any;
};

export const getKeyFromFilter: getKeyFromFilterType = {
  "/admin/users": "role",
  "/admin/roles": "possibilities",
};

export const actionsSticky = {
  "/transactions": true,
  "/admin/account-management": true,
  "/reports": true,
  "/custom-trades": true,
  "/trades": true,
};

export const columnsName = {
  SCREENSHOT_AND_BALANCE: true,
  REMOVE_AND_EDIT: true,
  ONLY_EDIT: true,
  CUSTOM_TRADES_ACTION: true,
  State: true,
};

export const stylesSticky = {
  position: "sticky",
  right: 0,
  background: "white",
  boxShadow: "5px 2px 5px grey",
};

export const accountSelectView: { [key: string]: any } = {
  "/user-statistics/novum": true,
  "/user-statistics/paxful": true,
  "/transactions": true,
  "/reports": true,
  "/auto-messaging": true,
};
