import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  IconButton,
  Toolbar,
  Typography,
  Button,
  Popover,
  Badge,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFetchNotificationsQuery,
  useFetchPossibilitiesQuery,
  useReadNotificationMutation,
  useRemoveNotificationMutation,
} from "shared/api/services/DataService";
import useGetRouteData from "shared/hooks/useGetRouteData";

import { useAppSelector } from "../../app/hooks";
import {
  useFetchMeQuery,
  useLazyFetchMeQuery,
} from "../../shared/api/services/MeService";
import { IPossibility } from "../../shared/constants";
import { changeLocation, hasModulePermission } from "../../shared/helpers";
import useAction from "../../shared/hooks/useAction";
import { notify } from "../../shared/utils/functions/notify";
import UserProfile from "../UserProfile";
import { SAppBar } from "./constants";

interface IAppBar {
  open: boolean;
  toggleDrawer: () => void;
}

const AppBar: React.FC<IAppBar> = ({ open, toggleDrawer }) => {
  const { currentPageName, moduleName } = useGetRouteData();
  const profile = useAppSelector((state) => state.me);
  const [modalOpen, setModalOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const { search } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { qty, notifications } = useAppSelector((state) => state.data);
  const params = new URLSearchParams(search);
  const [trigger] = useLazyFetchMeQuery();
  const { refetch } = useFetchMeQuery({
    root:
      params.get("account") || sessionStorage.getItem("accountId")
        ? undefined
        : true,
  });
  const [triggerReadNotify] = useReadNotificationMutation();
  useFetchNotificationsQuery();
  useFetchPossibilitiesQuery();
  const [removeNotification] = useRemoveNotificationMutation();
  const navigate = useNavigate();
  const { removeNotificationAction, readedNotifyAction } = useAction();

  useEffect(() => {
    if (search && !sessionStorage.getItem("accountId")) {
      sessionStorage.setItem("accountId", params.get("account") || "");
      sessionStorage.setItem("subDomain", "true");
      fetchMe();
    }
  }, [search]);

  const fetchMe = async () => {
    const { data, error } = await trigger({});
    if (!error) {
      const isAccessTrades = data.role.possibilities.find(
        (item: IPossibility) => item.name === "trades"
      );
      if (isAccessTrades) {
        navigate("/trades");
      } else {
        changeLocation({ navigate, possibilities: data.role.possibilities });
      }
    }
  };

  useEffect(() => {
    if (!profile?.id) {
      refetch();
    }
  }, []);
  const handleModalClose = () => {
    setModalOpen(false);
    setEditable(false);
  };
  const { t } = useTranslation("common");
  const [isLogged] = useState(true);

  const username = useMemo(
    () =>
      profile.id
        ? profile?.first_name || profile?.last_name || profile.username
        : "User",
    [profile]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteNotificationItem = async (id: string, idx: number) => {
    try {
      await removeNotification({ id });
      removeNotificationAction(idx);
      notify("success", "success Delete");
    } catch (e) {
      notify("error", "notification not deleted");
    }
  };

  const handleRead = async (id: string, isView: boolean, idx: number) => {
    if (!isView) {
      await triggerReadNotify({ id, body: { is_view: true } });
      readedNotifyAction(idx);
    }
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "notification" : undefined;

  const showNamePage = useMemo(() => {
    return hasModulePermission(moduleName || "", profile?.role?.possibilities);
  }, [currentPageName, profile?.role?.possibilities]);

  return (
    <SAppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
          {showNamePage && t(currentPageName as string)}
        </Typography>
        {isLogged ? (
          <>
            <Button id={id} onClick={handleClick}>
              <Badge badgeContent={qty} color="error">
                <NotificationsIcon />
              </Badge>
            </Button>
            <Button color="inherit" onClick={() => setModalOpen(true)}>
              {username}
            </Button>
          </>
        ) : (
          <Button color="inherit">Login</Button>
        )}
      </Toolbar>
      <UserProfile
        open={modalOpen}
        handleClose={handleModalClose}
        editable={editable}
        setEditable={setEditable}
      />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {notifications.length > 0 ? (
          <List>
            {notifications.map(
              (
                item: { type: string; id: string; isView: boolean },
                idx: number
              ) => (
                <ListItem
                  sx={{
                    cursor: "pointer",
                    "&:hover": { background: "#ECEFF1" },
                  }}
                  key={item.id}
                  onMouseEnter={(e) => handleRead(item.id, item.isView, idx)}
                >
                  <ListItemText
                    sx={{ marginRight: "10px" }}
                    primary={item.type}
                  />
                  {!item.isView && <Badge color="success" variant="dot" />}
                  <IconButton
                    sx={{ marginLeft: "30px" }}
                    onClick={() => handleDeleteNotificationItem(item.id, idx)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              )
            )}
          </List>
        ) : (
          <div style={{ width: "200px", height: "30px", textAlign: "center" }}>
            Empty data
          </div>
        )}
      </Popover>
    </SAppBar>
  );
};

export default AppBar;
