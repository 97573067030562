import {useEffect, useState} from "react";
import {getFirebaseToken, onForegroundMessage} from "../../firebase";


const useNotification = () => {
    const [showNotificationBanner, setShowNotificationBanner] = useState(
        Notification.permission === 'default'
    );
    const [value, setValue] = useState('');

    useEffect(() => {
        onForegroundMessage()
            .then((payload) => {
                console.log({value})
                console.log({showNotificationBanner})
                const {
                    notification: { title, body },
                }: any = payload;


                setValue(title);
                showNotification(title, body);
            })
            .catch((err) =>
                console.log(
                    'An error occured while retrieving foreground message. ',
                    err
                )
            )
            .finally(() => {
                setTimeout(() => setValue(''), 100);
            });
    }, []);
    const showNotification = (title: any, body: any) => {
        const notification = new Notification(title, { body });
        console.log({notification})
    };
    const handleGetFirebaseToken = () => {
        getFirebaseToken()
            .then((firebaseToken: any) => {
                if (firebaseToken) {
                    setShowNotificationBanner(false);
                }
            })
            .catch((err: any) =>
                console.error(
                    'An error occured while retrieving firebase token. ',
                    err
                )
            );
    };
    useEffect(() => {
        handleGetFirebaseToken();
    }, []);
};
export default useNotification;
