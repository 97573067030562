import { Fields } from "features/form-field-type/config";

export const userProfileFields: Array<Fields> = [
  {
    name: "username",
    type: "text",
    label: "Username",
    id: 1,
    variant: "standard",
    width: "100%",
    disabled: true,
  },
  {
    name: "first_name",
    type: "text",
    label: "First Name",
    id: 2,
    variant: "standard",
    width: "100%",
    disabled: true,
  },
  {
    name: "last_name",
    type: "text",
    label: "Last Name",
    id: 3,
    variant: "standard",
    width: "100%",
    disabled: true,
  },
  {
    name: "_2fa",
    type: "switch",
    label: "2FA",
    id: 4,
    width: "100%",
    variant: "",
    disabled: true,
  },
];

export const userProfileUpdateFields = ({ cb }: { cb: () => void }) => [
  {
    name: "username",
    type: "text",
    label: "Username",
    id: 1,
    variant: "standard",
    width: "100%",
  },
  {
    name: "first_name",
    type: "text",
    label: "First Name",
    id: 2,
    variant: "standard",
    width: "100%",
  },
  {
    name: "last_name",
    type: "text",
    label: "Last Name",
    id: 3,
    variant: "standard",
    width: "100%",
  },
  {
    name: "old_password",
    type: "password",
    label: "Old password",
    id: 4,
    variant: "standard",
    width: "100%",
    mr: "16px",
  },
  {
    name: "new_password",
    type: "password",
    label: "New password",
    id: 5,
    variant: "standard",
    width: "47%",
    mr: "16px",
  },
  {
    name: "password",
    type: "password",
    label: "Confirm password",
    id: 6,
    variant: "standard",
    width: "47%",
    mr: "16px",
  },
  {
    name: "_2fa",
    type: "switch",
    label: "2FA",
    id: 7,
    width: "10%",
    variant: "",
    cb,
  },
];
