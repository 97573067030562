import { styled } from "@mui/material/styles";
import {
  ProfileInitialValues,
  UpdateProfileInitialValues,
} from "features/form-field-type/config";
import * as Yup from "yup";

export const SProfileFormWrapper = styled("div")`
  .MuiTextField-root {
    margin-bottom: 16px;
  }
  margin-bottom: 24px;
`;

export const profileSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "min 2")
    .max(15, "max 15")
    .matches(/^\S+$/, "Username must not contain spaces")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Must contain only latin letters, numbers and underscore"
    )
    .required("Required"),
  first_name: Yup.string().min(2, "min 2").max(15, "max 15"),
  last_name: Yup.string().min(2, "min 2").max(15, "max 15"),
  fa2: Yup.boolean(),
});

export const profileUpdateSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "min 2")
    .max(15, "max 15")
    .matches(/^\S+$/, "Username must not contain spaces")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Must contain only latin letters, numbers and underscore"
    )
    .required("Required"),
  first_name: Yup.string()
    .min(2, "min 2")
    .max(15, "max 15")
    .matches(/^[aA-zZ\s]+$/, "Must contain only letters"),
  last_name: Yup.string()
    .min(2, "min 2")
    .max(15, "max 15")
    .matches(/^[aA-zZ\s]+$/, "Must contain only letters"),
  old_password: Yup.string()
    .min(8, "min 8")
    .max(50, "max 50")
    .matches(/^[a-zA-Z0-9]+$/, "Must contain only letters and numbers")
    .when("new_password", ([new_password], schema) => {
      if (!!new_password?.length) return schema.required("Required");
      return schema;
    })
    .when("password", ([password], schema) => {
      if (!!password?.length) return schema.required("Required");
      return schema;
    }),
  new_password: Yup.string()
    .min(8, "min 8")
    .max(50, "max 50")
    .matches(/^[a-zA-Z0-9]+$/, "Must contain only letters and numbers")
    .test("not-equal", "Old and new passwords are the same", function (value) {
      return !value || (!!value && this.parent.old_password !== value);
    }),
  password: Yup.string()
    .min(8, "min 8")
    .max(50, "max 50")
    .matches(/^[a-zA-Z0-9]+$/, "Must contain only letters and numbers")
    .oneOf([Yup.ref("new_password")], "Passwords must match")
    .test("not-equal", "Old and new passwords are the same", function (value) {
      return !value || (!!value && this.parent.old_password !== value);
    })
    .when("new_password", ([new_password], schema) => {
      if (!!new_password?.length) return schema.required("Required");
      return schema;
    }),
  _2fa: Yup.boolean(),
});

export const profileInitialValues: ProfileInitialValues = {
  username: "",
  first_name: "",
  last_name: "",
  _2fa: false,
};

export const updateProfileInitialValues: UpdateProfileInitialValues = {
  username: "",
  first_name: "",
  last_name: "",
  _2fa: false,
  old_password: "",
  new_password: "",
  password: "",
};
