import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { IMeState } from "app/entities/me/model";
import { UpdateProfileInitialValues } from "features/form-field-type/config";
import { FormikProps, useFormik } from "formik";
import { NavigateFunction } from "react-router-dom";
import { notify } from "shared/utils/functions/notify";

import {
  profileInitialValues,
  profileSchema,
  profileUpdateSchema,
  updateProfileInitialValues,
} from "./constants";

interface IUseUserProfileProps {
  profile: IMeState;
  updateProfile: any;
  removeToken: ActionCreatorWithoutPayload<"users/removeToken">;
  handleCloseDialog: () => void;
  navigate: NavigateFunction;
  logoutHandler: any;
  logoutWithReplaceDomain: any;
}

export const useUserProfile = ({
  profile,
  updateProfile,
  removeToken,
  handleCloseDialog,
  logoutHandler,
  navigate,
  logoutWithReplaceDomain,
}: IUseUserProfileProps) => {
  const updateProfileFormik: FormikProps<UpdateProfileInitialValues> =
    useFormik<UpdateProfileInitialValues>({
      initialValues: updateProfileInitialValues,
      validationSchema: profileUpdateSchema,
      onSubmit: async (values, { setErrors }) => {
        const data: UpdateProfileInitialValues = {
          username: values.username,
          first_name: values.first_name,
          last_name: values.last_name,
          _2fa: values._2fa,
        };
        if (values?.password) {
          data.password = values.password;
          data.old_password = values.old_password;
        }

        const { error } = await updateProfile(data);
        if (!error) {
          if (!!data.password || data._2fa !== profile._2fa) {
            notify(
              "success",
              "Security settings updated successfully. You must re-login"
            );
            setTimeout(() => {
              removeToken();
              sessionStorage.clear();
              logoutHandler();
              navigate("/auth", { replace: true });
            }, 2000);
            setTimeout(() => {
              logoutWithReplaceDomain();
              window.location.reload();
            }, 3000);
          }
          handleCloseDialog();
        } else {
          if (Array.isArray(error.data.detail)) {
            let formErrors = {};
            error.data.detail.forEach((e: any) => {
              formErrors = { ...formErrors, [e.loc[1]]: e.message };
            });
            setErrors(formErrors);
          } else {
            notify("error", error.data.detail);
          }
        }
      },
    });
  const profileFormik = useFormik({
    initialValues: profileInitialValues,
    validationSchema: profileSchema,
    onSubmit: () => {
      handleCloseDialog();
    },
  });
  return {
    updateProfileFormik,
    profileFormik,
  };
};
