import { useEffect } from "react";

import { useLazyFetchNotificationsQuery } from "../api/services/DataService";
import { BASE_URL } from "../config";
import { notify } from "../utils/functions/notify";

interface IProps {
  url: string;
  makeAccess: string;
  me: any;
  setModal?: any;
  handleReassignClick?: () => void;
}
const useEventSource = ({
  url,
  makeAccess,
  me,
  setModal,
  handleReassignClick,
}: IProps) => {
  const [notificationTrigger] = useLazyFetchNotificationsQuery();

  console.log({ makeAccess });
  console.log("session", sessionStorage.getItem("accountId"));
  useEffect(() => {
    let eventSource: any;
    const setupEventSource = () => {
      if (makeAccess) {
        eventSource = new EventSource(
          `${BASE_URL}${url}?account_id=${makeAccess}`,
          { withCredentials: true }
        );

        const handleEvent = (message: any) => {
          if (message.data) {
            notificationTrigger();
            const data = JSON.parse(message.data || "{}");
            if (data === null) {
              notify("warning", "Не осталось активных аккаунтов");
            } else {
              if (data.type === "reassign") {
                handleReassignClick?.();
              }
              if (data.user_id !== me.id) {
                if (data.is_active && setModal) {
                  setModal(true);
                }
              }
            }
          }
        };

        eventSource.addEventListener("True", handleEvent);

        eventSource.onerror = (error: any) => {
          // Обработка ошибок
        };
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setupEventSource();
      } else {
        if (eventSource) {
          eventSource.close();
        }
      }
    };

    setupEventSource(); // Инициализация EventSource при монтировании компонента

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (eventSource) {
        eventSource.close();
      }
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [makeAccess]);
};

export default useEventSource;
