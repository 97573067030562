import { createGlobalStyle } from "styled-components";

import { normalizeStyles } from "./normalize";

export const GlobalStyles = createGlobalStyle`
  ${normalizeStyles}
  .MuiList-root {
    &::after {
      height: 100%;
      content: none;
    }
  }
  .chat-lightbox {
    .yarl__navigation_next,  .yarl__navigation_prev {
      display: none;
    }
  }
  .selectAccount {
    margin-left: 30px;
    .MuiInputBase-root {
      margin-top: 0;
    }
    .MuiFormLabel-root {
      top: -15px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
 
`;
