import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import { FormikProps } from "formik";
import React from "react";

import { FieldType } from "../../constants";
import {useTranslation} from "react-i18next";

interface IProps {
  x: FieldType;
  formik: FormikProps<any>;
}
function valuetext(value: number) {
  return `${value}°C`;
}

function valueLabelFormat(value: number, prefix = "%") {
  return `${value}${prefix}`;
}
const SliderRange: React.FC<IProps> = ({ x, formik }) => {
  const { values, setFieldValue } = formik;
    const { t } = useTranslation("common");

  return (
    <FormControl sx={{ marginTop: x.mt, width: "100%" }}>
      <Typography
        sx={{ color: "rgba(0, 0, 0, 0.6);", fontSize: "13px" }}
        id="input-slider"
        gutterBottom
      >
        {t(x.label || "")}
      </Typography>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={values[x.name]}
        onChange={(event, newValue) => {
          setFieldValue(x.name, newValue);
        }}
        getAriaValueText={valuetext}
        min={Number(x.min)}
        max={Number(x.max)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => valueLabelFormat(value, x.prefix)}
      />
    </FormControl>
  );
};

export default SliderRange;
