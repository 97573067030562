import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { AxiosResponse } from "axios";
import { tableStructure } from "features/table-constructor/config";
import { baseApi } from "shared/api/xhr";
import { IResponseData } from "shared/constants/roles-management";

export const dataApi: any = baseApi.injectEndpoints({
  endpoints: (build): EndpointDefinitions =>
    ({
      checkIsAuth: build.query({
        query: () => ({
          url: "/auth/is-authenticated",
          method: "GET",
        }),
      }),
      fetchDataList: build.query({
        query: ({ url, ...params }) => ({
          url,
          method: "GET",
          params,
        }),
        keepUnusedDataFor: 1,
      }),
      fetchListViews: build.query({
        query: ({ pageId }) => ({
          url: `/list-views:${pageId}`,
        }),
      }),
      createView: build.mutation({
        query: (body) => ({
          url: "/views",
          method: "POST",
          body,
        }),
      }),
      editView: build.mutation({
        query: ({ id, body }) => ({
          url: `/views:${id}`,
          method: "PATCH",
          body,
        }),
      }),
      selectedView: build.mutation({
        query: ({ body }) => ({
          url: `/views:${body.id}`,
          method: "PATCH",
          body,
        }),
      }),
      fetchTable: build.query({
        query: ({ url, pathname, activeTab, ...params }) => ({
          url,
          method: "GET",
          params,
        }),
        keepUnusedDataFor:
          window.location.pathname === "/admin/account-management" ? 0 : 60,
        transformResponse: (
          baseQueryReturnValue: AxiosResponse<IResponseData>,
          meta,
          arg
        ) => {
          const { body, head, qty }: any = baseQueryReturnValue;

          if (Array.isArray(baseQueryReturnValue)) {
            return {};
          }

          return tableStructure({
            headings: head,
            body,
            activeTab: arg?.activeTab,
            roleType: arg?.roleType,
            path: window.location.pathname,
            qty,
          });
        },
      }),
      fetchRoles: build.query({
        query: () => ({
          url: "/roles",
          method: "GET",
        }),
        transformResponse: (
          baseQueryReturnValue: AxiosResponse<IResponseData>
        ) => {
          // @ts-ignore
          const { body } = baseQueryReturnValue;

          return {
            data: body.map(({ id, name, type }: any) => ({ id, name, type })),
          };
        },
      }),
      removeRow: build.mutation({
        query: ({ url }) => ({
          url,
          method: "DELETE",
        }),
      }),
      fetchActiveUsersDashboard: build.query({
        query: ({ ...params }) => ({
          url: "/dashboard/active-users",
          method: "GET",
          params,
        }),
      }),
      fetchDashboard: build.query({
        query: ({ ...params }) => ({
          url: "/dashboard/",
          method: "GET",
          params,
        }),
        transformResponse: (baseData) => {
          return baseData.data.map((item: any) => ({
            date: item.period,
            value: item.amount,
          }));
        },
      }),
      fetchTotalsDashboard: build.query({
        query: ({ ...params }) => ({
          url: "/dashboard/totals",
          method: "GET",
          params,
        }),
      }),
      fetchBalanceChartDashboard: build.query({
        query: ({ ...params }) => ({
          url: "/dashboard/balance",
          method: "GET",
          params,
        }),
      }),
      fetchNotifications: build.query({
        query: () => ({
          url: "/notifications/",
          method: "GET",
        }),
      }),
      readNotification: build.mutation({
        query: ({ id, body }) => ({
          url: `/notifications/:${id}`,
          method: "PATCH",
          body,
        }),
      }),
      removeNotification: build.mutation({
        query: ({ id }) => ({
          url: `/notifications/:${id}`,
          method: "DELETE",
        }),
      }),
      fetchPossibilities: build.query({
        query: () => ({
          url: "/possibilities",
          method: "GET",
        }),
      }),
      addCardToTrade: build.mutation({
        query: ({ id, body }) => ({
          url: `/credit-cards/add-card:${id}`,
          method: "POST",
          body,
        }),
      }),
      updateCardToTrade: build.mutation({
        query: ({ id, body, tradeId }) => ({
          url: `/credit-cards/update/card:${id}/trade:${tradeId}`,
          method: "PATCH",
          body,
        }),
      }),
      checkBalanceCard: build.mutation({
        query: ({ body, isChecked, expDate, ...params }) => ({
          url: isChecked
            ? `/credit-cards/get-gift-card-balance?creditCard=${body.number}&expDate=${expDate}&cvv=${body.cvv}`
            : `/credit-cards/check-balance`,
          method: isChecked ? "GET" : "POST",
          body,
          params,
        }),
      }),
      removeCardFromTrade: build.mutation({
        query: ({ cardId, ...params }) => ({
          url: `/credit-cards/remove/card:${cardId}`,
          method: "DELETE",
          params,
        }),
      }),
      fetchFakeCustomerNext: build.query({
        query: ({ index, ...params }) => ({
          url: `/fake-customers/next`,
          method: "GET",
          params,
        }),
        keepUnusedDataFor: 0,
      }),
      fetchCheckoutCharge: build.mutation({
        query: ({ body, ...params }) => ({
          url: "/gateways/charge",
          method: "POST",
          body,
          params,
        }),
      }),
      fetchCheckoutChargeResult: build.mutation({
        query: ({ body, ...params }) => ({
          url: "/gateways/charge/results",
          body,
          params,
        }),
      }),
      fetchReopenTrade: build.mutation({
        query: ({ ...params }) => ({
          url: "/trades/reopen",
          method: "POST",
          params,
        }),
      }),
      fetchReleaseTrade: build.mutation({
        query: ({ isCustomSection, ...params }) => ({
          url: isCustomSection
            ? "/trades/custom_trade/release"
            : "/trades/release",
          method: "POST",
          params,
        }),
      }),
      fetchApprove: build.mutation({
        query: ({ ...params }) => ({
          url: "/trades/approve",
          method: "POST",
          params,
        }),
      }),
      fetchTradeDetail: build.query({
        query: ({ url }) => ({
          url,
          method: "GET",
        }),
        keepUnusedDataFor: 1,
      }),
      updateCardAndPhotoVerification: build.mutation({
        query: ({ body, ...params }) => ({
          url: "/trades/update/verification-status",
          method: "PATCH",
          body,
          params,
        }),
      }),
      createCustomTrade: build.mutation({
        query: ({ body }) => ({
          url: "/trades/custom_trade/create",
          method: "POST",
          body,
        }),
      }),
      updateCustomTrade: build.mutation({
        query: ({ body, hash, ...params }) => ({
          url: `/trades/custom_trade/update:${hash}`,
          method: "PATCH",
          body,
          params,
        }),
      }),
      uploadImagesCustomTrade: build.mutation({
        query: ({ files, tradeId }) => ({
          url: `/trades/custom_trade/${tradeId}/upload_image`,
          method: "POST",
          body: files,
        }),
      }),
      removeImageCustomTrade: build.mutation({
        query: ({ id, ...params }) => ({
          url: `/trades/custom_trade/${id}/delete_image`,
          method: "DELETE",
          params,
        }),
      }),
      fetchCheckoutTransaction: build.query({
        query: ({ ...params }) => ({
          url: "/transactions",
          params,
        }),
      }),
      fetchEditReport: build.mutation({
        query: ({ body, id }) => ({
          url: `/reports/update_custom_trade_report/:${id}`,
          method: "PATCH",
          body,
        }),
      }),
      fetchImagesCustomTradeDetails: build.query({
        query: ({ tradeHash }) => ({
          url: `/trades/custom_trade/${tradeHash}/images`,
        }),
        keepUnusedDataFor: 1,
      }),
      fetchCancelTrade: build.mutation({
        query: ({ id, ...params }) => ({
          url: `/trades/custom_trade/${id}/cancel`,
          method: "POST",
          params,
        }),
      }),
      fetchApproveTrade: build.mutation({
        query: ({ id, ...params }) => ({
          url: `/trades/custom_trade/${id}/approve`,
          method: "POST",
          params,
        }),
      }),
      fetchChargeStripe: build.mutation({
        query: ({ body, ...params }) => ({
          url: "/gateways/charge/stripe",
          method: "POST",
          params,
          body,
        }),
      }),
      fetchGroupedGateways: build.query({
        query: () => ({
          url: `/gateways/grouped-gateways`,
        }),
        transformResponse: (baseData: any) => {
          return {
            gateways: (baseData || []).map((item: any) => ({
              label: item.gateway,
              name: item.gateway,
            })),
            payments: (baseData || []).reduce((acc: any, cur: any) => {
              acc[cur.gateway] = cur.payments.map((item: any) => ({
                ...item,
                payment: `${item.payment} - ${item.name}`,
              }));
              return acc;
            }, {}),
          };
        },
      }),
      freezeTrade: build.mutation({
        query: ({ ...params }) => ({
          url: `/trades/freeze/enable-disable`,
          method: "POST",
          params,
        }),
      }),
      activateTime: build.mutation({
        query: ({ ...params }) => ({
          url: `/trades/activating/waiting-time`,
          method: "POST",
          params,
        }),
      }),
    } as EndpointDefinitions),
});
export const {
  useActivateTimeMutation,
  useFreezeTradeMutation,
  useFetchGroupedGatewaysQuery,
  useFetchChargeStripeMutation,
  useFetchApproveTradeMutation,
  useFetchCancelTradeMutation,
  useFetchImagesCustomTradeDetailsQuery,
  useFetchEditReportMutation,
  useUpdateCustomTradeMutation,
  useRemoveImageCustomTradeMutation,
  useFetchCheckoutTransactionQuery,
  useUploadImagesCustomTradeMutation,
  useFetchCheckoutChargeResultMutation,
  useCreateCustomTradeMutation,
  useUpdateCardAndPhotoVerificationMutation,
  useFetchTradeDetailQuery,
  useFetchApproveMutation,
  useFetchReopenTradeMutation,
  useFetchReleaseTradeMutation,
  useFetchCheckoutChargeMutation,
  useFetchFakeCustomerNextQuery,
  useRemoveCardFromTradeMutation,
  useCheckBalanceCardMutation,
  useUpdateCardToTradeMutation,
  useAddCardToTradeMutation,
  useCheckIsAuthQuery,
  useFetchPossibilitiesQuery,
  useReadNotificationMutation,
  useRemoveNotificationMutation,
  useFetchNotificationsQuery,
  useLazyFetchNotificationsQuery,
  useFetchDashboardQuery,
  useFetchBalanceChartDashboardQuery,
  useLazyFetchTotalsDashboardQuery,
  useFetchTotalsDashboardQuery,
  useFetchActiveUsersDashboardQuery,
  useFetchDataListQuery,
  useFetchTableQuery,
  useFetchRolesQuery,
  useRemoveRowMutation,
  useLazyFetchDataListQuery,
  useFetchListViewsQuery,
  useCreateViewMutation,
  useSelectedViewMutation,
}: any = dataApi;
