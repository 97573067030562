import { reduce } from "lodash";
import { matchRoutes, useLocation } from "react-router-dom";
import { IPage, PAGES } from "shared/constants/page";

export default function useGetRouteData() {
  const location = useLocation();
  const result =
    matchRoutes(PAGES(), location) ||
    matchRoutes(
      reduce(
        PAGES(),
        function (acc, current) {
          current.subpages && current.subpages.forEach((sub) => acc.push(sub));
          return acc;
        },
        [] as Array<IPage>
      ),
      location
    );
  let moduleName = null;
  PAGES().forEach((p) => {
    if (p.path === location.pathname) {
      moduleName = p.name;
    } else if (p?.subpages) {
      p.subpages.forEach((subPage) => {
        if (subPage.path === location.pathname) {
          moduleName = subPage.name;
        }
      });
    }
  });
  const currentPageName = result && result[0]?.route.element;
  return {
    currentPageName: currentPageName,
    moduleName,
  };
}
