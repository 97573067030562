import { Button, Stack } from "@mui/material";
import { RootState } from "app/store";
import FormConstructor from "features/form-constructor";
import GoogleAuthenticator from "features/google-authenticator";
import { useGoogleAuthDialog } from "features/google-authenticator/hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLogoutMutation,
  useUpdateMeMutation,
} from "shared/api/services/MeService";
import useAction from "shared/hooks/useAction";
import { SimpleDialog } from "shared/ui";

import { SProfileFormWrapper, updateProfileInitialValues } from "./constants";
import { useUserProfile } from "./hooks";
import { userProfileFields, userProfileUpdateFields } from "./lib/helpers";

interface IUserProfile {
  open: boolean;
  handleClose: () => void;
  editable: boolean;
  setEditable: (arg: boolean) => void;
}

const UserProfile: React.FC<IUserProfile> = ({
  open,
  handleClose,
  editable,
  setEditable,
}) => {
  const { t } = useTranslation("common");
  const [updateProfile] = useUpdateMeMutation();
  const [logoutHandler] = useLogoutMutation();
  const profile = useSelector((state: RootState) => state.me);

  const { removeToken } = useAction();
  const navigate = useNavigate();
  const logoutWithReplaceDomain = () => {
    if (profile?.account_id) {
      // window.location.replace("https://novum.akhter.studio/auth");
    }
  };
  const { updateProfileFormik, profileFormik } = useUserProfile({
    profile,
    updateProfile,
    removeToken,
    logoutHandler,
    logoutWithReplaceDomain,
    handleCloseDialog: handleClose,
    navigate,
  });
  const {
    secretCode,
    dialogIsOpen,
    handleCloseDialog,
    googleAuthFormik,
    handle2FA,
  } = useGoogleAuthDialog({
    formik: updateProfileFormik,
  });

  const cancelEdit = () => {
    const initialData = profile.id
      ? {
          ...profile,
          old_password: "",
          new_password: "",
          password: "",
        }
      : updateProfileInitialValues;
    updateProfileFormik.setValues(initialData);
    setEditable(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (profile.id) {
        profileFormik.setValues(profile);
        updateProfileFormik.setValues({
          ...updateProfileInitialValues,
          ...profile,
        });
      }
    }, 1000);
    // eslint-disable-next-line
  }, [profile]);

  const logout = () => {
    logoutWithReplaceDomain();
    removeToken();
    logoutHandler();
    sessionStorage.clear();
    navigate("/auth", { replace: true });
  };
  return (
    <SimpleDialog
      open={open}
      handleClose={handleClose}
      title={t("PROFILE_DIALOG_TITLE")}
      fullWidth
    >
      <SProfileFormWrapper>
        {editable ? (
          <FormConstructor
            fields={userProfileUpdateFields({ cb: handle2FA })}
            formik={updateProfileFormik}
            columns={1}
            formWidth={536}
          />
        ) : (
          <FormConstructor
            fields={userProfileFields}
            formik={profileFormik}
            columns={1}
            formWidth={536}
          />
        )}
      </SProfileFormWrapper>
      {editable ? (
        <Stack direction={"row"} spacing={2}>
          <Button fullWidth variant={"outlined"} onClick={cancelEdit}>
            {t("PROFILE_DIALOG_CANCEL_BUTTON")}
          </Button>
          <Button
            fullWidth
            variant={"contained"}
            onClick={updateProfileFormik.submitForm}
          >
            {t("PROFILE_DIALOG_SAVE_BUTTON")}
          </Button>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Button onClick={() => setEditable(true)} variant={"outlined"}>
            {t("PROFILE_DIALOG_EDIT_BUTTON")}
          </Button>
          <Button color="error" onClick={logout}>
            {t("PROFILE_DIALOG_LOGOUT_BUTTON")}
          </Button>
        </Stack>
      )}
      <GoogleAuthenticator
        open={dialogIsOpen}
        secret={secretCode}
        handleClose={handleCloseDialog}
        formik={googleAuthFormik}
        fullWidth
      />
    </SimpleDialog>
  );
};

export default UserProfile;
