import { Box, Button, Grid, Stack, SxProps } from "@mui/material";
import appStoreImg from "assets/apple-store.svg";
import googlePlayImg from "assets/google-play.svg";
import { FormikProps } from "formik";
import React from "react";
import QRCode from "react-qr-code";

import { SimpleDialog } from "../../shared/ui";
import FormConstructor from "../form-constructor";
import { fields, IGoogleAuthFields, SImage, SStack } from "./constants";

interface IGoogleAuthenticatorProps {
  secret: string;
  open: boolean;
  handleClose: () => void;
  formik: FormikProps<IGoogleAuthFields>;
  sx?: SxProps;
  fullWidth?: boolean;
}

const GoogleAuthenticator: React.FC<IGoogleAuthenticatorProps> = ({
  open,
  handleClose,
  formik,
  sx,
  fullWidth,
  secret,
}) => {
  return (
    <SimpleDialog
      open={open}
      handleClose={handleClose}
      title={"Google Authenticator"}
      description={
        "Download Google authenticator on your mobile device and enter 2FA Code"
      }
      sx={sx}
      fullWidth={fullWidth}
    >
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid item xs={4}>
          <Box>
            <QRCode size={175} style={{ maxWidth: "100%" }} value={secret} />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormConstructor fields={fields} formik={formik} />
          <SStack direction="row" spacing={2}>
            <Box>
              <a
                rel="noreferrer"
                href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                target="_blank"
              >
                <SImage src={appStoreImg} alt="" />
              </a>
            </Box>
            <Box>
              <a
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"
                target="_blank"
              >
                <SImage src={googlePlayImg} alt="" />
              </a>
            </Box>
          </SStack>
        </Grid>
      </Grid>
      <Stack sx={{ mt: 3 }} direction={"row"} spacing={2}>
        <Button onClick={handleClose} fullWidth variant={"outlined"}>
          Cancel
        </Button>
        <Button
          disabled={!!Object.keys(formik.errors).length}
          fullWidth
          variant={"contained"}
          onClick={formik.submitForm}
        >
          Enter Code
        </Button>
      </Stack>
    </SimpleDialog>
  );
};

export default GoogleAuthenticator;
