import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { FormikProps } from "formik";
import React from "react";
import styled from "styled-components";

import { FieldType } from "../../constants";
import {useTranslation} from "react-i18next";

interface IGroupCheckbox {
  x: FieldType;
  formik: FormikProps<any>;
}

type GroupFieldType = {
  columns: number | undefined;
};
const GroupedField = styled.div`
  display: grid;
  grid-template-columns: ${(props: GroupFieldType) =>
    `repeat(${props.columns || 1},  ${100 / (props.columns || 1)}%)`};
`;
const GroupCheckbox: React.FC<IGroupCheckbox> = ({ formik, x }) => {
  const { setFieldValue, values } = formik;
  const { t } = useTranslation("common");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    let checkboxes = { ...values[x.name] };
    if (name === "all") {
      Object.keys(checkboxes).forEach(
        (el) => (checkboxes[el] = e.target.checked)
      );
    }
    checkboxes = {
      ...checkboxes,
      [name]: !checkboxes[name],
    };
    setFieldValue(x.name, checkboxes);
  };
  const isAllChecked = Object.keys(values[x.name] || {})
    .filter((el) => el !== "all")
    .every((el) => values[x.name][el]);

  return (
    <FormControl
      sx={{ minWidth: x.width || "100%", marginTop: x.mt }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel component="legend">{t(x.label || "")}</FormLabel>
      <GroupedField columns={x.columns}>
        {x.data.map((el: FieldType) => {
          return (
            <React.Fragment key={el.id}>
              {values[x.name] !== undefined && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        el.name === "all"
                          ? isAllChecked
                          : Boolean(values[x.name][el.name])
                      }
                      onChange={(e) => {
                        handleChange(e, el.name);
                      }}
                    />
                  }
                  label={t(el.label || "")}
                />
              )}
            </React.Fragment>
          );
        })}
      </GroupedField>
    </FormControl>
  );
};

export default GroupCheckbox;
