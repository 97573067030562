import { styled } from "@mui/material/styles";

export const SLayoutLogo = styled("div")`
  padding: ${(props: {open: boolean}) => !props.open ? "0" : "28px 15px 36px"};
  margin:${(props: {open: boolean}) => !props.open ? "10px auto" : "0"};
  img {
    display: block; 
    object-fit: cover;
    width: ${(props: {open: boolean}) => !props.open ? "50px" : "auto"};
  }
`;
