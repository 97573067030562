import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { NavigateFunction } from "react-router-dom";
import { messageTypes } from "shared/constants/messaging";

import { getKeyFromFilter } from "../../features/table-constructor/config";
import { IPossibility } from "../constants";
import { PAGES } from "../constants/page";

dayjs.extend(utc);
dayjs.extend(timezone);
export const columnsParseQuery = (obj: any) => {
  return (
    Object.keys(obj.columns)
      .filter((x: string) => x !== "all")
      .filter((x) => obj.columns[x])
      .join(",") || undefined
  );
};

const subDomainProd = (row: any) => {
  sessionStorage.clear();
  const newSubdomain = row.key_name.replaceAll(" ", "");
  const currentURL = window.location.href;
  const urlParts = currentURL.split("//");
  const protocol = urlParts[0];
  const domainAndPath = urlParts[1].split("/");
  const domain = domainAndPath[0];
  const checkIsSub = window.location.host.split(".");
  if (checkIsSub.length === 2) {
    // Создаем новый URL с новым субдоменом и старым путем
    const newURL = `${protocol}//${newSubdomain}.${domain}/${domainAndPath
      .slice(1)
      .join("/")}?account=${row.id}`;
    window.open(newURL, "_blank");
    return;
  }
  if (checkIsSub.length > 2) {
    window.open(
      `${window.location.origin.replace(
        checkIsSub[0],
        newSubdomain
      )}/admin/account-management?account=${row.id}`
    );
  } else {
    window.open(
      `${window.location.origin}/admin/account-management?account=${row.id}`
    );
  }
};

const subDomainDev = (row: any) => {
  sessionStorage.clear();
  const newSubdomain = row.key_name.replaceAll(" ", "");
  const currentURL = window.location.href;
  const urlParts = currentURL.split("//");
  const protocol = urlParts[0];
  const domainAndPath = urlParts[1].split("/");
  const domain = domainAndPath[0];
  const checkIsSub = window.location.host.split(".");
  if (
    !currentURL.includes(newSubdomain.toLowerCase()) &&
    checkIsSub.length &&
    checkIsSub[0] === "novum" &&
    checkIsSub[1] === "akhter"
  ) {
    // Создаем новый URL с новым субдоменом и старым путем
    const newURL = `${protocol}//${newSubdomain}.${domain}/${domainAndPath
      .slice(1)
      .join("/")}?account=${row.id}`;
    window.open(newURL, "_blank");
    return;
  }
  if (checkIsSub.length > 1) {
    window.open(
      `${window.location.origin.replace(
        checkIsSub[0],
        newSubdomain
      )}/admin/account-management?account=${row.id}`
    );
  } else {
    window.open(
      `${window.location.origin}/admin/account-management?account=${row.id}`
    );
  }
};

export const addedSubDomain = (row: any) => {
  window.location.host.includes("novum.akhter.studio")
    ? subDomainDev(row)
    : subDomainProd(row);
};

export const getFormatMultiSelect = (data: any) => {
  if (data?.methods) {
    return data.methods.filter(
      (item: { slug: string; name: string }, idx: number) => {
        return (
          data.methods.findIndex(
            (obj: { slug: string; name: string }) => obj.name === item.name
          ) === idx
        );
      }
    );
  }
  if (data?.currencies) {
    return data.currencies;
  }
  if (data?.data) {
    return data.data;
  }
  if (data?.body) {
    return data.body;
  }
  if (data?.partners) {
    return data.partners;
  }
  if (Array.isArray(data)) {
    return data;
  }

  return [];
};

export const removeEmptyValues = (data: any) => {
  let obj = { ...data };
  for (let key in obj) {
    if (!obj[key]) {
      delete obj[key];
    }
  }
  return obj;
};

export const offerTypeTitle = {
  Sell: "sell",
  Buy: "buy",
};

const parseFilterOffers = (obj: any) => {};
const parseFilterMessage = (obj: any) => {
  const newObj = { ...obj };
  if (newObj.messageType) {
    const messageType = messageTypes.find(
      (msgType) => msgType.id === newObj.messageType
    );
    newObj.messageType = {
      title: `(Message type) ${messageType?.name}`,
      id: newObj.messageType,
      fieldName: "messageType",
    };
  }
  return newObj;
};

const filterParseData = (obj: any) => {
  return {
    "/offers": parseFilterOffers(obj),
    "/auto-messaging": parseFilterMessage(obj),
  };
};

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const parseFormatDataFilter = (obj: any) => {
  //@ts-ignore
  const data = filterParseData(obj)[window.location.pathname];
  return (
    []
      //@ts-ignore
      .concat(...Object.values(data))
      .filter((x) => x)
  );
};

export interface IGetPagePossibilitiesType {
  [key: string]: any;
}

export const getPagePossibilitiesId = {
  "users-management": "/admin/users",
  "roles-management": "/admin/roles",
  "external-accounts-management": "/admin/account-management",
  gateways: "/admin/gateways",
  "fake-customers": "/fake-customers",
  "external-users-statistics": "/user-statistics/paxful",
  "users-statistics": "/user-statistics/novum",
  offers: "/offers",
  "aa9d5023-dc48-4d03-a9b5-d01862cf7092": "/messaging",
  "offer-competition": "/offer-competition",
  "auto-messaging": "/auto-messaging",
  reports: "/reports",
  trades: "/trades",
  transactions: "/transactions",
};

export const downloadFile = async (data: File, filename: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const a = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const hasModulePermission = (
  page: string,
  possibilities?: Array<IPossibility>
) => {
  return !!possibilities?.find((p: IPossibility) => p.name === page);
};

export const changeLocation = ({
  navigate,
  possibilities,
}: {
  navigate: NavigateFunction;
  possibilities?: Array<IPossibility>;
}) => {
  if (!possibilities) {
    return;
  }
  PAGES().forEach((pageRoute, index) => {
    if (hasModulePermission(pageRoute?.name, possibilities)) {
      navigate(pageRoute.path, { replace: true });
    } else if (pageRoute?.subpages) {
      pageRoute?.subpages?.forEach((p) => {
        if (hasModulePermission(p.name, possibilities)) {
          navigate(p.path, { replace: true });
        }
      });
    }
  });
};

export const getNotEmptyData = (source: any) => {
  const data: any = {};
  for (const key in source) {
    if (
      source.hasOwnProperty(key) &&
      source[key] !== null &&
      source[key] !== undefined &&
      source[key].length !== 0
    ) {
      data[key] = source[key];
    }
  }
  return data;
};

export const objectToQueryParams = (obj: { [key: string]: any }) => {
  const params = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return params.join("&");
};

interface IArrayToConvertObj {
  [key: string]: any;
}

export const arrayToConvertObj = (arr: { id: number }[]) => {
  let obj: IArrayToConvertObj = {};
  if (arr.length) {
    arr.forEach((x) => (obj[x.id] = obj));
  }
  return obj;
};

type multiSelectType = {
  name: string;
  slug: string;
};

export const multiSelectConvertData = (
  valuesArr: multiSelectType[],
  dataArr: multiSelectType[],
  key: string
) => {
  const map = new Map(
    valuesArr.map(({ name, slug }: any) => [name, { name, slug }])
  );
  return dataArr.filter((item: any) => map.get(item[key]));
};

export const parseDataFilterView = (filter: any, pathname: string) => {
  if (Array.isArray(filter)) {
    return filter;
  }

  if (
    filter &&
    typeof filter === "object" &&
    filter[getKeyFromFilter[pathname]]
  ) {
    return filter[getKeyFromFilter[pathname]];
  }
  return filter || {};
};

export const parseFormatDate = (date: string | Date, format?: string) => {
  return dayjs(date).format(format || "");
};

export const checkExistFilter = (filter: any) => {
  if (Array.isArray(filter)) {
    return filter.length > 0;
  }
  if (filter && typeof filter === "object") {
    return Object.values(filter)
      .flat()
      .find((x) => {
        if (typeof x === "object" && x !== null) {
          // @ts-ignore
          return Object.values(x).find((x) => Boolean(x));
        }
        return Boolean(x);
      });
  }
  return false;
};
export const getSecondsFormatted = (seconds: number) => {
  if (!seconds) {
    return "";
  }
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
};

export const getTimeDifferenceFromNow = (thenString: string) => {
  const now = dayjs();
  const then = dayjs(thenString);
  const secondsPassed = now.diff(then, "seconds");
  return getSecondsFormatted(secondsPassed);
};

export const expireMaskDate = (value: string) => {
  let inputValue = value;

  // Оставляем только цифры и ограничиваем длину введенной строки 4 символами
  let numericValue = inputValue.replace(/[^\d]/g, "").slice(0, 4);

  // Добавляем разделитель "/" между месяцем и годом, если достаточно символов
  if (numericValue.length > 2) {
    numericValue = numericValue.replace(/^(\d{2})(\d{0,2})$/, "$1/$2");
  }

  // Обновляем значение в инпуте
  value = numericValue;
  return value;
};

export function removeSubdomain(url: string) {
  return url.replace(/(?:https?:\/\/)?(?:\w+\.)?([^\/]+)/, "$1");
}

export const accountId = sessionStorage.getItem("accountId");

export const emptyStringToNull = (obj: any) => {
  for (let item in obj) {
    if (obj[item] === "") {
      obj[item] = null;
    }
  }

  return obj;
};

export const parseCardsFromCheckouts = (cardId?: string, arr?: any) => {
  if (cardId) {
    return arr.map((item: any) => {
      if (item.id === cardId) {
        return { ...item, used: true };
      }
      return item;
    });
  }
  return arr.map((item: any) => (!item.used ? { ...item, used: true } : item));
};

export const differenceMinutes = (date: string) => {
  if (!date) {
    return true;
  }
  const givenDate: any = new Date(date); // Заданная дата
  const currentDate: any = new Date(); // Текущая дата

  const differenceInMinutes = (currentDate - givenDate) / (1000 * 60); // Разница в минутах

  if (differenceInMinutes >= 60) {
    return true;
  } else {
    return false;
  }
};

export function hasSixtyMinutesPassed(isoDate: any) {
  const date: any = new Date(isoDate);
  const now: any = new Date();
  const sixtyMinutes = 60 * 60 * 1000; // 60 минут в миллисекундах

  return now - date > sixtyMinutes;
}

export function getRemainingSecondsOrZero(isoDate: any) {
  const startDate = dayjs.utc(isoDate);

  const now = dayjs.utc();

  const differenceInMinutes = now.diff(startDate, "minute");
  console.log({ differenceInMinutes });
  if (differenceInMinutes >= 60) {
    return {
      time: Date.now(),
    };
  } else {
    const remainingTime = startDate.add(60, "minute").diff(now);
    return Date.now() + remainingTime;
  }
}
