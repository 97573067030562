import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "shared/api/xhr";

export const paxfulAccountApi = baseApi.injectEndpoints({
  endpoints: (build): EndpointDefinitions =>
    ({
      createPaxfulAccount: build.mutation({
        query: ({ body }) => ({
          url: "/paxful-accounts",
          method: "POST",
          body,
        }),
      }),
      updatePaxfulAccount: build.mutation({
        query: ({ data, itemId }) => ({
          url: `/paxful-accounts:${itemId}`,
          method: "PATCH",
          body: data,
        }),
      }),
      activateAccount: build.mutation({
        query: ({ body, id }) => ({
          url: `/paxful-accounts:${id}/activate`,
          method: "POST",
          body,
        }),
      }),
    } as EndpointDefinitions),
});

export const {
  useCreatePaxfulAccountMutation,
  useUpdatePaxfulAccountMutation,
  useActivateAccountMutation,
}: any = paxfulAccountApi;
