import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { baseApi } from "../../xhr";

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (build) =>
    ({
      editCryptoBuyRate: build.mutation({
        query: (body) => ({
          url: `/reports/:${body.id}/crypto-buy-rate?crypto_buy_rate=${body.cryptoBuyRate}&account_id=${body.account_id}`,
          method: "PATCH",
        }),
      }),
      updateProcessorFee: build.mutation({
        query: ({ body, id, pathname }) => ({
          url:
            pathname === "/reports"
              ? `/reports/update_processor_fee/:${id}`
              : `/gateways/processor_fee:${id}`,
          method: "PATCH",
          body,
        }),
      }),
    } as EndpointDefinitions),
});

export const {
  useEditCryptoBuyRateMutation,
  useUpdateProcessorFeeMutation,
}: any = reportsApi;
