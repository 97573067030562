import { Box, CssBaseline } from "@mui/material";
import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useCheckIsAuthQuery } from "shared/api/services/DataService";
import { drawerWidth } from "shared/constants/layout";
import AppBar from "widgets/AppBar";
import Navigation from "widgets/Navigation";

import Loader from "../../shared/ui/Loader";
import { SDrawer, DrawerHeader, Main } from "./constants";
import LayoutLogo from "./ui/Logo";

interface ILayout {
  children: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const { isLoading, error } = useCheckIsAuthQuery();
  const { pathname } = useLocation();
  const toggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };
  if (error) return <Navigate to="/auth" />;
  if (isLoading) return <Loader />;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} toggleDrawer={toggleDrawer} />
      <SDrawer
        sx={{
          width: !open ? 65 : drawerWidth,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: !open ? 65 : drawerWidth,
            boxSizing: "border-box",
            overflowX: "hidden",
          },
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        <LayoutLogo open={open} />
        <Navigation open={open} />
      </SDrawer>
      <Main open={open} style={pathname === "/" ? {} : { overflowY: "hidden" }}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default Layout;
