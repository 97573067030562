import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "shared/api/xhr";

import { IPossibility } from "../../../constants";

export const meApi = baseApi.injectEndpoints({
  endpoints: (build): EndpointDefinitions =>
    ({
      fetchMe: build.query({
        query: ({ ...params }) => ({
          url: `/auth/users/me`,
          method: "GET",
          params,
        }),
        transformResponse: (baseData: any) => {
          const arr = [...baseData.role.possibilities];
          const isCustom = baseData.role.type === "Custom";
          const isRead = baseData.role.possibilities.find(
            (item: IPossibility) => item.name === "external-accounts-management"
          );
          if (!isRead && !isCustom) {
            arr.push({
              created_at: "2023-03-28T13:03:58.463020",
              id: "5bd513ac-077d-4202-bd06-2bb0d9cbda84",
              name: "external-accounts-management",
              title: "Accounts Management",
              updated_at: null,
            });
          }

          return {
            ...baseData,
            onlyRead: !Boolean(isRead),
            role: {
              ...baseData.role,
              possibilities: arr,
            },
            account_id:
              sessionStorage.getItem("accountId") || baseData.account_id,
          };
        },
      }),
      updateMe: build.mutation({
        query: (body) => ({
          url: "/users/me",
          method: "PATCH",
          body,
        }),
      }),
      logout: build.mutation({
        query: () => ({
          url: "/auth/logout",
          method: "POST",
        }),
      }),
    } as EndpointDefinitions),
});

export const {
  useLogoutMutation,
  useFetchMeQuery,
  useLazyFetchMeQuery,
  useUpdateMeMutation,
}: any = meApi;
