import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import i18next from "i18next";
import { BASE_URL } from "shared/config";

const axiosRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
const axiosRequestClean = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const getInterceptorRequest = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.request.use((config: any) => {
    config.headers["Accept-Language"] = i18next.language;
    return config;
  });
};

const getInterceptorResponse = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      // @ts-ignore
      if (error.response?.data?.detail === "Not authenticated") {
        window.location.href = "/auth";
      }
      return Promise.reject(error);
    }
  );
};

getInterceptorResponse(axiosRequest);
getInterceptorRequest(axiosRequest);

getInterceptorRequest(axiosRequestClean);

export { axiosRequest, axiosRequestClean };
