import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldType } from "../../constants";

interface IProps {
  formik: FormikProps<any>;
  x: FieldType;
  isFetchingErrors?: any;
  checkOnChangeField?: (arg: boolean) => void;
}

const TextFieldInput: React.FC<IProps> = ({
  formik,
  isFetchingErrors = {},
  x,
  checkOnChangeField,
}) => {
  const { t } = useTranslation("common");
  const { values, touched, handleBlur, setFieldValue, errors } = formik;
  const isError =
    (values[x.name] || touched[x.name]) &&
    (isFetchingErrors[x.name] || errors[x.name]);
  return (
    <FormControl
      sx={{
        marginRight: x.mr,
        marginTop: x.mt,
        minWidth: x.width || "100%",
        maxWidth: x.width || "100%",
      }}
    >
      {values[x.name] !== undefined && (
        <TextField
          // @ts-ignore
          variant={x.variant}
          value={values[x.name]}
          label={
            typeof x.label === "string"
              ? t(x.label as string)
              : x?.label?.[values?.[x?.dataFromKey]] || x?.otherLabel
          }
          inputProps={{
            autoComplete: "off",
            form: {
              autocomplete: "off",
            },
            ...x?.inputProps,
          }}
          InputProps={x.InputProps}
          disabled={x?.disabled}
          name={x.name}
          type={x.onlyNumber ? "number" : "text"}
          onBlur={handleBlur}
          error={Boolean(isError)}
          multiline={x.multiline}
          required={x?.required}
          maxRows={x.maxRows}
          onKeyDown={(evt) => {
            if (x.onlyNumber) {
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
            }
          }}
          rows={x.rows}
          helperText={
            (isError &&
              (t(errors[x.name] as string) || isFetchingErrors[x.name])) ||
            t(x?.helperText as string)
          }
          onChange={({ target: { value } }) => {
            if (x?.maxNumber && x.maxNumber < Number(value)) {
              return;
            }
            if (x?.maxValueCount && x.maxValueCount < value.length) {
              return;
            }
            if (checkOnChangeField) {
              checkOnChangeField(true);
            }
            if (x.pattern) {
              (x.pattern.test(value) || !value) && setFieldValue(x.name, value);
            } else {
              setFieldValue(x.name, value);
            }
          }}
        />
      )}
    </FormControl>
  );
};

export default TextFieldInput;
