import { useAppSelector } from "app/hooks";
import { lazy, useState } from "react";
import { Routes, Route, Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import useEventSource from "shared/hooks/useEventSource";
import Layout from "widgets/Layout";
import PaxfulActivate from "widgets/PaxfulActivate";

import useAction from "../shared/hooks/useAction";
import useNotification from "../shared/hooks/useNotifications";
import ProtectedRoute from "./ProtectedRoute";

const Dashboard = lazy(() => import("./Dashboard"));
const Auth = lazy(() => import("./Auth"));
const Users = lazy(() => import("./Admin/Users"));
const Roles = lazy(() => import("./Admin/Roles"));
const PaxfulAccountManagement = lazy(() => import("./Admin/Paxful"));
const Trades = lazy(() => import("./Trades"));
const CustomTrades = lazy(() => import("./CustomTrades"));
const Transactions = lazy(() => import("./Transactions"));
const Reports = lazy(() => import("./Reports"));
const Messaging = lazy(() => import("./Messaging"));
const Offers = lazy(() => import("./Offers"));
const OfferCompetition = lazy(() => import("./OfferCompetition"));
const NoonesUserStatistics = lazy(() => import("./UserStatistics/Noones"));
const NovumUserStatistics = lazy(() => import("./UserStatistics/Novum"));
const FakeTraders = lazy(() => import("./FakeTraders"));
const Gateways = lazy(() => import("./Gateways"));
export const Routing = () => {
  const me = useAppSelector((state) => state.me);
  const [modal, setModal] = useState(false);
  const { setIsReassignTrade } = useAction();
  const handleReassignClick = () => setIsReassignTrade(true);
  useNotification();
  useEventSource({
    url: "/paxful-accounts/event-stream",
    makeAccess: me?.id,
    setModal,
    handleReassignClick,
    me,
  });
  return (
    <>
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route element={<ProtectedRoute />}>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={"/trades"} element={<Trades />} />
            <Route path={"/custom-trades"} element={<CustomTrades />} />
            <Route path={"/transactions"} element={<Transactions />} />
            <Route path={"/reports"} element={<Reports />} />
            <Route path={"/auto-messaging"} element={<Messaging />} />
            <Route path={"/offers"} element={<Offers />} />
            <Route path={"/offer-competition"} element={<OfferCompetition />} />
            <Route path={"user-statistics"}>
              <Route path={"paxful"} element={<NoonesUserStatistics />} />
              <Route path={"novum"} element={<NovumUserStatistics />} />
            </Route>
            <Route path={"/fake-customers"} element={<FakeTraders />} />
            <Route path="admin">
              <Route path="users" element={<Users />} />
              <Route path="roles" element={<Roles />} />
              <Route
                path="account-management"
                element={<PaxfulAccountManagement />}
              />
              <Route path={"gateways"} element={<Gateways />} />
            </Route>
          </Route>
        </Route>
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <PaxfulActivate open={modal} close={() => setModal(!modal)} />
    </>
  );
};
