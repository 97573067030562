import axios from "axios";
import { FormikProps, useFormik } from "formik";
import { useState } from "react";
import { axiosRequest } from "shared/api/xhr";
import { notify } from "shared/utils/functions/notify";

import { UpdateProfileInitialValues } from "../form-field-type/config";
import {
  googleAuthInitialValues,
  googleAuthSchema,
  IGoogleAuthFields,
} from "./constants";

export const useGoogleAuthDialog = ({
  formik,
}: {
  formik: FormikProps<UpdateProfileInitialValues>;
}) => {
  const [googleAuthSecret, setGoogleAuthSecret] = useState("");
  const [googleAuthOpen, setGoogleAuthOpen] = useState(false);
  const googleAuthFormik: FormikProps<IGoogleAuthFields> =
    useFormik<IGoogleAuthFields>({
      initialValues: googleAuthInitialValues,
      validationSchema: googleAuthSchema,
      onSubmit: async (values) => {
        try {
          await axiosRequest.post("/auth/2fa/confirm", {
            code: values.fa2,
          });
          setGoogleAuthOpen(false);
          await formik.setValues((values: any) => ({
            ...values,
            _2fa: !values._2fa,
          }));
        } catch (e) {
          if (axios.isAxiosError(e) && e?.response?.data?.detail) {
            notify("error", e?.response?.data?.detail);
          }
          console.error("error", e);
        }
      },
    });
  const handle2FA: () => void = async () => {
    const { detail }: any = await axiosRequest.post("/auth/2fa/uri");
    setGoogleAuthSecret(detail);
    googleAuthFormik.setValues((values) => ({ ...values, secret: detail }));
    setGoogleAuthOpen(true);
  };
  return {
    secretCode: googleAuthSecret,
    dialogIsOpen: googleAuthOpen,
    handleCloseDialog: () => setGoogleAuthOpen(false),
    handle2FA,
    googleAuthFormik,
  };
};
