import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
  },
});

export const withMui = (component: () => React.ReactNode) => () =>
  <ThemeProvider theme={theme}>{component()}</ThemeProvider>;
