import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { dataApi } from "shared/api/services";

import { getPagePossibilitiesId } from "../../../../shared/helpers";

export type Notifications = {
  assignedUser: string;
  createdAt: string;
  id: string;
  isView: boolean;
  type: string;
  updatedAt: string;
};

export interface IMockedCard {
  id?: string;
  creditCard: string;
  expDate: string;
  cvv: string;
  method: string;
  remaining: string;
  currency: string;
  used?: boolean;
  isNew?: boolean;
  isChecked?: boolean;
}

export const initialMockedCard = (id: string) => {
  return {
    id,
    creditCard: "",
    expDate: "",
    cvv: "",
    method: "",
    remaining: "",
    currency: "",
    isNew: true,
    used: false,
  };
};

const initialMockedCards: Array<IMockedCard> = [initialMockedCard(nanoid())];
interface IInitialValues {
  notifications: Notifications[];
  qty: number;
  paymentCards: IMockedCard[];
  pagePossibilitiesId?: any;
  isReassignTrade: boolean;
}

const initialState: IInitialValues = {
  notifications: [],
  qty: 0,
  paymentCards: initialMockedCards,
  pagePossibilitiesId: {},
  isReassignTrade: false,
};

export const dataModel = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {
    setIsReassignTrade: (state, { payload }) => {
      state.isReassignTrade = payload;
    },
    updatePagePossibilitiesId: (state, { payload }) => {},
    addPaymentCardAction: (state, { payload }) => {
      state.paymentCards.push(initialMockedCard(nanoid()));
    },
    updatePaymentCardAction: (state, { payload }) => {
      // const { index, value, name } = payload;
      // state.paymentCards[index].data[name] = value;
    },
    removePaymentCardAction: (state, { payload }) => {
      state.paymentCards = state.paymentCards.filter(
        (card) => card.id !== payload
      );
    },
    removeNotificationAction: (state, { payload }) => {
      if (state.qty > 0 && !state.notifications[payload].isView) {
        state.qty = state.qty - 1;
      }
      state.notifications.splice(payload, 1);
    },
    readedNotifyAction: (state, { payload }) => {
      state.notifications[payload].isView = true;
      if (state.qty > 0) {
        state.qty = state.qty - 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        dataApi.endpoints.fetchNotifications.matchFulfilled,
        (state, { payload }) => {
          state.notifications = payload.notifications;
          state.qty = payload.qty;
        }
      )
      .addMatcher(
        dataApi.endpoints.fetchPossibilities.matchFulfilled,
        (state, { payload }) => {
          for (let item of payload) {
            if (
              getPagePossibilitiesId[
                item.name as keyof typeof getPagePossibilitiesId
              ]
            ) {
              // @ts-ignore
              state.pagePossibilitiesId[getPagePossibilitiesId[item.name]] =
                item.id;
            }
          }
        }
      );
  },
});

const dataActions = dataModel.actions;
export { dataActions };
