import { combineReducers } from "@reduxjs/toolkit";
import { meModel } from "app/entities/me/model";
import { dictionariesModel } from "app/entities/model/dictionaries";
import { usersModel } from "app/entities/model/users";
import {
  usersApi,
  rolesApi,
  dataApi,
  paxfulAccountApi,
  fakeCustomerApi,
  gatewaysApi,
  offersApi,
  reportsApi,
} from "shared/api/services";
import { meApi } from "shared/api/services/MeService";
import {dataModel} from "../../entities/model/data";
export const rootReducer = combineReducers({
  users: usersModel.reducer,
  dictionaries: dictionariesModel.reducer,
  data: dataModel.reducer,
  me: meModel.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [meApi.reducerPath]: meApi.reducer,
  [paxfulAccountApi.reducerPath]: paxfulAccountApi.reducer,
  [fakeCustomerApi.reducerPath]: fakeCustomerApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [gatewaysApi.reducerPath]: gatewaysApi.reducer,
  [offersApi.reducerPath]: offersApi.reducer,
});
