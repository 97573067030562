import { GlobalStyles } from "app/styles";
import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  fontFamily: "Roboto",
  mainPrimary: "#1976D2",
};
export const withStyledComponents =
  (component: () => React.ReactNode) => () => {
    return (
      <ThemeProvider theme={theme}>
        {component()}
        <GlobalStyles />
      </ThemeProvider>
    );
  };
