import { FormHelperText, LinearProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useFetchDataListQuery } from "../../api/services/DataService";
import { FieldType } from "../../constants";

interface IProps {
  formik: FormikProps<any>;
  x: FieldType;
}

const hiddenAllOption: { [key: string]: any } = {
  "/trades": true,
  "/reports": true,
  "/admin/account-management": true,
  "/admin/gateways": true,
};
const SelectField: React.FC<IProps> = ({ formik, x }) => {
  const { data, isLoading } = useFetchDataListQuery(
    { url: x.fetchUrl },
    { skip: !x.fetchUrl }
  );
  const { pathname } = useLocation();
  const { t } = useTranslation("common");
  const { touched, errors, values, handleBlur, setFieldValue } = formik;

  return (
    <>
      {values[x.name] !== undefined && (
        <FormControl
          error={Boolean(touched[x.name] && errors[x.name])}
          variant="standard"
          sx={{
            minWidth: x.width || "100%",
            marginTop: x.mt,
            marginRight: x.mr,
          }}
        >
          <InputLabel>
            {typeof x.label === "string"
              ? t(x.label as string)
              : x?.label?.[x?.dataFromKey] || x?.otherLabel}
            {x.required && " *"}
          </InputLabel>
          <Select
            onBlur={handleBlur}
            value={values[x.name]}
            onChange={(e) => {
              setFieldValue(x.name, e.target.value);
              if (x.targetClearValue) {
                setFieldValue(x.targetClearValue, "");
              }
            }}
            label="Age"
          >
            {!hiddenAllOption[pathname] && (
              <MenuItem value="">{t("TRADES_FIELD_TRADE_STATUS_ALL")}</MenuItem>
            )}
            {(
              data?.[x?.mappingKey as keyof typeof x] ||
              data?.body ||
              x.dataFromKey?.[
                values?.[x?.mappingKey as keyof typeof x] as keyof typeof x
              ] ||
              x.data ||
              []
            ).map((el: any, idx: number) => (
              <MenuItem
                key={idx}
                value={el?.[x?.valueKey as keyof typeof x] || el.id}
              >
                {el?.[x?.displayKey as keyof typeof x] ||
                  t(el.name) ||
                  t(el.username)}
              </MenuItem>
            ))}
          </Select>
          {touched[x.name] && errors[x.name] && (
            <FormHelperText style={{ color: "red" }}>
              {errors[x.name] as string}
            </FormHelperText>
          )}
          {x.fetchUrl && isLoading && <LinearProgress />}
        </FormControl>
      )}
    </>
  );
};

export default SelectField;
