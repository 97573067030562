import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "shared/api/xhr";

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) =>
    ({
      login: build.mutation({
        query: (body) => ({
          url: "/auth/login",
          method: "POST",
          credentials: "include",
          body,
        }),
      }),
      usersList: build.query({
        query: (body) => ({
          url: "/users",
          method: "GET",
          body,
        }),
      }),
      getFilteredUsers: build.query({
        query: (body) => ({
          url: "/users?possibilities=trade-detail,trades&exclude_admin=true",
          method: "GET",
          body,
        }),
      }),
      usersForTrades: build.query({
        query: (body) => ({
          url: "/users?by_roles_name=Redeemer,Custom",
          method: "GET",
          body,
        }),
      }),
      createUser: build.mutation({
        query: (body) => ({
          url: "/users",
          method: "POST",
          body,
        }),
      }),
      editUser: build.mutation({
        query: (values) => ({
          url: `/users:${values.id}`,
          method: "PATCH",
          body: values,
        }),
      }),
      fetchUserDetail: build.mutation({
        query: (id) => ({
          url: `/users:${id}`,
          method: "GET",
        }),
      }),
      fetchUsersStatistics: build.query({
        query: ({ username }) => ({
          url: `/paxful-statistics:${username}`,
          method: "GET",
        }),
      }),
      fetchUsersOnline: build.query({
        query: ({ ...params }) => ({
          url: `/users_online_offline`,
          params,
        }),
      }),
    } as EndpointDefinitions),
});
export const {
  useUsersListQuery,
  useGetFilteredUsersQuery,
  useLazyUsersForTradesQuery,
  useLoginMutation,
  useCreateUserMutation,
  useFetchUserDetailMutation,
  useEditUserMutation,
  useFetchUsersStatisticsQuery,
  useFetchUsersOnlineQuery,
}: any = usersApi;
