import { useAppSelector } from "app/hooks";
import React from "react";
import { Outlet } from "react-router-dom";
import { hasModulePermission } from "shared/helpers";
import useGetRouteData from "shared/hooks/useGetRouteData";
import TradesNotifyAlarm from "widgets/Trades/TradesNotifyAlarm";

interface IProtectedRoute {
  condition?: boolean;
  redirectTo?: string;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  condition = true,
  redirectTo = "/auth",
  ...rest
}) => {
  const { role } = useAppSelector((state) => state.me);
  const { moduleName } = useGetRouteData();
  const hasPermission =
    moduleName && hasModulePermission(moduleName, role?.possibilities);
  return condition && (!role || hasPermission) ? (
    <>
      <Outlet />
      <TradesNotifyAlarm />
    </>
  ) : (
    <></>
  );
};

export default ProtectedRoute;
