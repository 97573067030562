import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>
  );
};

export default Loader;
