import ClearIcon from "@mui/icons-material/Clear";
import { FormControl } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  formik: FormikProps<any>;
  x: any;
}

const DatePicker: React.FC<IProps> = ({ x, formik }) => {
  const { values, setFieldValue } = formik;
  const { t } = useTranslation("common");
  const minDateSelected = useMemo(() => {
    if (values?.fromDate) {
      return dayjs(values?.fromDate);
    }
  }, [values?.fromDate]);
  return (
    <FormControl
      sx={{
        width: x.width || "100%",
        marginRight: x.mr || "",
        marginTop: x.mt || "",
      }}
      className="date-wrapper"
    >
      <DemoItem label={t(x.label || "")}>
        <MobileDatePicker
          value={values[x.name] || null}
          onChange={(date) => setFieldValue(x.name, date)}
          minDate={x.name === "toDate" ? minDateSelected : null}
        />
      </DemoItem>
      {values[x.name] && (
        <div className="date-clear" onClick={() => setFieldValue(x.name, "")}>
          <ClearIcon />
        </div>
      )}
    </FormControl>
  );
};

export default DatePicker;
