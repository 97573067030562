import { EndpointDefinitions } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "shared/api/xhr";
import { IBody } from "shared/constants/roles-management";

export const rolesApi = baseApi.injectEndpoints({
  endpoints: (build) =>
    ({
      fetchRoles: build.query({
        query: (params) => ({
          url: "/roles",
          method: "GET",
          params,
        }),
        transformResponse: (baseQueryReturnValue: any) => {
          const { body, head } = baseQueryReturnValue;
          const newBody = body.map((x: IBody, index: number) => ({
            ...x,
            "#": index + 1,
          }));
          const newHead = { "#": "#", ...head };
          return {
            head: newHead,
            body: newBody,
          };
        },
      }),
      createRole: build.mutation({
        query: (body) => ({
          url: "/roles",
          method: "POST",
          body,
        }),
      }),
      editRole: build.mutation({
        query: ({ body, id }) => ({
          url: `/roles:${id}`,
          method: "PATCH",
          body,
        }),
      }),
      fetchRoleDetail: build.query({
        query: ({ id }) => ({
          url: `/roles:${id}`,
          method: "GET",
        }),
      }),
      fetchCheckIsExists: build.query({
        query: ({ ...params }) => ({
          url: "/roles/is-exists",
          method: "GET",
          params,
        }),
      }),
    } as EndpointDefinitions),
});
export const {
  useFetchRolesQuery,
  useCreateRoleMutation,
  useLazyFetchRoleDetailQuery,
  useEditRoleMutation,
  useLazyFetchCheckIsExistsQuery,
}: any = rolesApi;
