import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import {useTranslation} from "react-i18next";

const FieldPassword = ({ x, values, setFieldValue, formik }: any) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { touched, errors, handleBlur } = formik;
  const { t } = useTranslation("common");

  return (
    <>
      {values[x.name] !== undefined && (
        <TextField
          id="outlined-adornment-password"
          variant={x.variant}
          type={isShowPassword ? "text" : "password"}
          value={values[x.name]}
          name={x.name}
          disabled={x?.disabled}
          onBlur={handleBlur}
          error={Boolean(touched[x.name] && errors[x.name])}
          helperText={touched[x.name] && (errors[x.name] as string)}
          onChange={(e) => setFieldValue(x.name, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  edge="end"
                >
                  {isShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={t(x.label)}
        />
      )}
    </>
  );
};

export default FieldPassword;
