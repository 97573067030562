import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useFetchDataListQuery } from "shared/api/services/DataService";
import { getFormatMultiSelect } from "shared/helpers";

import { useAppSelector } from "../../../app/hooks";
import { FieldType } from "../../constants";

interface IProps {
  formik: FormikProps<any>;
  x: FieldType;
  name: string;
}

const groupFilter = {
  "/offers": true,
  "/trades": true,
  "/auto-messaging": true,
  "/transactions": true,
  "/reports": true,
  "/": true,
};

const MultiSelectField: React.FC<IProps> = ({ formik, x, name }) => {
  const me = useAppSelector((state) => state.me);
  const { values, setValues } = formik;
  const { pathname } = useLocation();
  const { t } = useTranslation("common");
  const { data } = useFetchDataListQuery(
    { url: x.fetchUrl + `?account_id=${me.account_id}` },
    { skip: !x.fetchUrl }
  );
  if (groupFilter[pathname as keyof typeof groupFilter]) {
    console.log({ data });
    return (
      <>
        {values[x.name] && (
          <FormControl
            sx={{
              marginTop: x.mt,
              width: x.width || "100%",
              marginRight: x.mr,
            }}
          >
            <Autocomplete
              multiple
              id="tags-standard"
              options={x.localData ? x.localData : getFormatMultiSelect(data)}
              getOptionLabel={(option: any) => {
                if (x.key) {
                  return option[x.key];
                }
                return option?.code || option?.title || option?.name || option;
              }}
              value={values[x.name]}
              isOptionEqualToValue={(option: any, value: any) => {
                if (x.key) {
                  return option[x.key] === value[x.key];
                }
                return option.name === value.name;
              }}
              onChange={(event, value) => {
                setValues({
                  ...values,
                  [x.name]: value,
                });
              }}
              defaultValue={values[x.name] || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t(x.label as string)}
                  placeholder={t(x.placeholder as string) || ""}
                />
              )}
            />
          </FormControl>
        )}
      </>
    );
  }
  return (
    <>
      {Array.isArray(values) && (
        <Autocomplete
          multiple
          id="tags-standard"
          options={x.localData ? x.localData : getFormatMultiSelect(data)}
          getOptionLabel={(option: FieldType) => option?.title || option?.name}
          onChange={(event, value) => {
            setValues(value);
          }}
          defaultValue={Array.isArray(values) ? values : []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={t(x.label as string)}
              placeholder={t(x.placeholder as string) || ""}
            />
          )}
        />
      )}
    </>
  );
};

export default MultiSelectField;
